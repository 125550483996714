###
Project entrypoint view
TODO: Replace image with real content
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import moment from 'moment-mini'
import {ILITEAPI} from 'libs/geoportal.js'
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
import { div, p, ul, li, img, span, iframe, a } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _ErrorDisplay from 'components/ErrorDisplay'
ErrorDisplay = React.createFactory _ErrorDisplay

import _EmptyState from '@atlaskit/empty-state'
EmptyState = React.createFactory _EmptyState

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import {
	Map as _Map
	Marker as _Marker
	GoogleApiWrapper
	} from 'google-maps-react'
Map = React.createFactory _Map
Marker = React.createFactory _Marker

# Data
import { RootQuery } from './data'
import trLegal from 'data/local/legal'

# Styles
import styles from './index.styl'

# Elements
import getModals from './elements/modals'
import getTable from './elements/table'

GOOGLE_MAPS_API_KEY = 'AIzaSyCqSxJpQ3CTy2RlTtoU-oYf2Z9Fxltd1ZI'

groupsInColumnsPlacement = [
		[
			"legal"
			"mock_legal"
			"skanska-cde"
			'mock_skanska-cde'
			"controlling"
			"accounting"
			"taxes"
			"facility"
			"marketing"
			"tqt"
			"leasing"
			"asset-management"
			"consultant"
			"mock_consultant"
		]
	,
		[
			"skanska-sa"
		]
	,
		[
			"architects",
			"mock_architects",
		]
	]

export class ProjectView extends React.Component
	constructor: (props) ->
		super props
		@state =
			activeModal: null

	getModals: getModals
	getTable: getTable

	# LOGIC
	getMembersOfGroups: (members, groups) ->
		{result} = _.reduce groups, (acc) ->
			group = _.head acc.availableGroups

			[membersOfGroup, notMembersOfGroup] = _.partition acc.availableMembers, (member) -> group.id in _.map member.user.groups, 'id'
			memberWithGroup = 	_.map membersOfGroup, (member) -> {...member, group}
			{
				result: [...acc.result, ...memberWithGroup]
				availableMembers: notMembersOfGroup
				availableGroups: _.without acc.availableGroups, group
			}
		, {
			result: []
			availableMembers: members
			availableGroups: groups
		}
		result

	# SUBCOMPONENTS
	renderColumn: ({header, content, className}) ->
		div {className: cnames styles.section, className},
			header
			content

	renderTable: ({headers, rows, className}) =>
		div {className: cnames className},
			div { className: cnames styles.grid, styles.table},
				_.map headers, (columnHeader, index) ->
					span {key: index, className: styles.title}, columnHeader
			@renderSeparator {subtle: true}
			_.map rows, (row, index) =>
				Fragment {},
					div { className: cnames styles.grid, styles.table},
						_.map row, (item, index) -> span {key: index}, item
					if index isnt ((_.size rows) - 1 )
						@renderSeparator {subtle: true}

	renderHeadlessTable: ({rows}) =>
		Fragment {},
			_.map rows, (row, index) =>
				{ header, content } = row
				Fragment {},
					div { key: index, className: cnames styles.grid, styles.headerlessTable},
						span {key: index, className: styles.title}, header
						span {}, if content? then content else '-'
					if index isnt ((_.size rows) - 1 )
						@renderSeparator {subtle: true}

	renderItem: ({value, label, className}) ->
		div {className: cnames styles.item, className},
			div {className: styles.label}, label
			div {className: styles.value}, value

	renderPersonItem: ({user, roles, group}, className) ->
		div {className: cnames styles.personItem, className},
			img
				src: user.picture.original
				className: styles.picture
			div {className: styles.informations},
				div {className: styles.name}, user.name
				div {className: styles.position}, _.join (_.map roles, 'name'), ', '
				a {href: "mailto:#{user.email}", className: styles.email }, user.email
				div {className: styles.phone}, if user.telephone? then user.telephone else '-'

	renderSeparator: (props = {}) ->
		div {className: cnames styles.separator, if props?.subtle? then styles.subtle}

	renderHeader: (args) ->
		if _.isObject args
			{header, onClick, isDisabled} = args
		else
			header = args
		div {className: styles.header},
			header
			if onClick? and !isDisabled
				Button {
					className: styles.editButton
					content: 'Edit'
					appearance: 'subtle'
					onClick
				}, 'Edit'

	renderEmptyState: (text) -> div {className: styles.emptyState}, text

	renderGeoportalMap: (geolocation) ->
		x = if geolocation? then geolocation.lng
		y = if geolocation? then geolocation.lat
		ILITEAPI.init
			divId : "geoportalMap"
			width : '100%'
			height : '100%'
			activeGpMapId: "gp4"
			scale: 5000
			marker:
				x: x
				y : y
				sr: 4326
				scale : 5000
				show: false

	renderManagementSection: (project) =>
		Fragment {},
			@renderManagementColumn 'SSP', project, groupsInColumnsPlacement[0]
			@renderManagementColumn 'SA', project, groupsInColumnsPlacement[1]
			@renderManagementColumn 'EXT', project, groupsInColumnsPlacement[2]

	renderManagementColumn: (type, project, acceptableGroups) =>
		{members, projectManagement, userGroups} = project
		acceptableGroups = _.compact _.map acceptableGroups, (slug) -> _.find userGroups, {slug}
		members = @getMembersOfGroups members, acceptableGroups
		if !_.isEmpty members
			div {className: cnames styles.managmentSection},
				@renderHeader header: type
				div {className: cnames styles.grid, styles.peopleSection, if (_.size members) < 8 then styles.narrow},
					_.map members, (member) => @renderPersonItem member

	render: ->
		UserInfoConsumer {}, ({ability, me}) =>
			div {className: cnames @props.className },
				Query
					query: RootQuery
					variables:
						projectSlug: @props.match.params.project
						documentsFilters:
							topics: ['bGVnYWxUb3BpYzo1YzY4NjRlNzg3YzBmYjZiZWVjMTkzNjQ=']
					onCompleted: (data) =>
						if !_.isEmpty data.project
							@setState
								originalSlug: data.project.slug
				, ({data, loading, error, refetch}) =>
					if loading then  Spinner {}
					else
						ErrorDisplay {
							error
							refetch
							withImage: true
						}, () =>
							{project} = data
							if _.isEmpty project
								EmptyState
									header: trLegal.error.invalidProject
									imageUrl: require 'assets/empty-states/empty-result.svg'
									maxImageWidth: 300
									maxImageHeight: 300
									primaryAction:
										Button
											href: "/projects"
										, "Go to Projects List"
							else
								geoLocationData = if project?.location?.coordinates?
									coords = project.location.coordinates
									{lat: coords[0], lng: coords[1]}

								div {className: styles.base},
									Fragment {},
										@renderItem
											label: project.name
											value: 'Project Summary'
											className: styles.headerItem
										div {className: styles.content},
											img
												src: require('assets/skanska_seal.png')
												className: styles.logo

											div {className: cnames styles.grid, styles.generalSection},
												@renderColumn
													header: @renderHeader
														header: 'Info'
														isDisabled: !(ability.can 'update', 'Project')
														onClick: () => @setState activeModal: 'editProject'
													content: Fragment {},
														@renderItem
															label: 'Project Name'
															value: project.name
														@renderItem
															label: 'Abbrevation'
															value: project.code
														@renderItem
															label: 'Address'
															value: do ->
																{street, buildingNumber, postalCode, city} = project
																if street? and city?
																	"#{street} #{if buildingNumber? then buildingNumber else ''}, #{if postalCode? then postalCode else ''} #{city}"
																else '-'
														# @renderItem
														# 	label: 'Region'
														# 	value: if project.region? then project.region else '-'
														@renderItem
															label: 'SPV Developer / owner'
															value: if project.spv?.name? then project.spv.name
														if project.dates?
															_.map project.dates, (date) =>
																@renderItem
																	label: _.capitalize _.upperCase date.type
																	value: moment.unix(date.value).format('DD/MM/YYYY')

														# @renderTable
														# 	className: styles.darkTable
														# 	headers: ['Stage', 'PZA', 'NLA', 'GLA']
														# 	rows: [
														# 			['Stage_test', '-', '-', '-']
														# 		,
														# 			['Stage_test', '-', '-', '-']
														# 		,
														# 			['Stage_test', '-', '-', '-']
														# 		,
														# 			['Sum', '-', '-', '-']
														# 	]

												@renderColumn
													# header: @renderHeader 'Preview'
													content:
														if !project.photo?
															@renderEmptyState 'Add project preview to display photo'
														else
															img {src: project.photo.original, className: styles.imgResponsive}
												@renderColumn
													# header: @renderHeader 'Location'
													content: Fragment {},
														if _.isEmpty geoLocationData
															@renderEmptyState 'Set project location to display map'
														else
															div {key: (geoLocationData.lat*geoLocationData.lng), className: styles.map},
																Map
																	google: @props.google
																	zoom: 16
																	initialCenter: geoLocationData
																	gestureHandling: "cooperative"
																, Marker {}
											@renderSeparator()

											div {className: cnames styles.grid, styles.landSection},
												@renderColumn
													header: @renderHeader 'Land Specification'
													content: Fragment {},
														@getTable {
															items: project.legalDocuments
															loading
														}
												@renderColumn
													header: @renderHeader 'Map'
													content:
														div {className: styles.map},
														if !geoLocationData?
															@renderEmptyState 'Set project location to display map'
														else
															# do => if ILITEAPI? then @renderGeoportalMap geoLocationData
															div id: 'geoportalMap'
											@renderSeparator()

											div {className: cnames styles.grid, styles.companySection},
												@renderColumn
													header: @renderHeader
														header: 'Company data'
														isDisabled: !(ability.can 'update', 'Project')
														onClick: () => @setState activeModal: 'editCompany'
													content:
														if !project.spv?
															div {}, '–'
														else
															@renderHeadlessTable
																rows: [
																	header: 'SPV Developer / subtitute developer'
																	content: project.spv.name
																,
																	header: 'KRS'
																	content: project.spv.krs
																,
																	header: 'NIP'
																	content: project.spv.nip
																,
																	header: 'REGON'
																	content: project.spv.regon
																,
																	header: 'Headquarters'
																	content: if project.spv.hedquarters?
																		{street, buildingNumber, postalCode, city} = project.spv.hedquarters
																		if street? and buildingNumber? and postalCode? and city?
																			"#{street} #{buildingNumber}, #{postalCode} #{city}"
																,
																	header: 'Introduction'
																	content: project.spv.description
																,
																	header: 'Representation'
																	content: project.spv.representationDescription
																,
																	header: 'Representation type'
																	content: project.spv.representationType
																]
											@renderSeparator()

											@renderColumn
												header: @renderHeader
													header:  'Project managment'
													isDisabled: !(ability.can 'update', 'Project')
													onClick: () => @props.history.push "#{@props.match.url}/users"
												content:
													if !project.projectManagement?
														@renderEmptyState 'Please contact administrator to configure project management roles for this project'
													else
														div {className: cnames styles.managmentSections},
															@renderManagementSection project


										@getModals {project}

export default GoogleApiWrapper({apiKey: GOOGLE_MAPS_API_KEY})(ProjectView)
