import gql from 'graphql-tag'

# export GroupQuery = gql """
# 	query GroupDetail (
# 		$id: ID!
# 		$pagination: PaginationInput
# 		$sort: UsersSort
# 		) {
# 		userGroup(id: $id) {
# 			id
# 			name
# 			slug
# 			description
# 			roles
# 			picture {
# 				mini
# 			}
# 			members(sort: $sort, pagination: $pagination) {
# 					... on Person {
# 						id
# 						name
# 						email
# 						picture {
# 							mini
# 						}
# 						department
# 						position
# 						telephone
# 						company { id name }
# 						projectsPermissions {
# 							project {
# 								id
# 								slug
# 								name
# 							}
# 							roles {
# 								name
# 							}
# 						}

# 					}
# 					... on Service {
# 						id
# 					}
# 			}
# 	}
# }

# """

export GroupQuery = gql """
		query GroupDetail (
		$id: ID!
		$pagination: PaginationInput
		) {
		userGroup(id: $id) {
			id
			name
			slug
			description
			picture {
				mini
			}
			members(pagination: $pagination) {
			... on ActorAccess{
        user {
        	... on Person{
            id
						name
						email
						picture {
							mini
						}
						department
						position
						telephone
						company { id name }
						memberships {
							...on UserGroupAccess {
								group {
									id
									name
								}
								roles {
										name
								}
							}
              ...on ProjectAccess {
                project {
                  id
									name
                }
                roles {
                  name
                }
              }
						}
          }
        }
      }
		}
	}
}

"""
