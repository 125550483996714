import _ from 'lodash'
import React from 'react'

import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import {
	add
} from '@bevy/theme/icons'
import styles from '../index.styl'

export default ({members, selected, ability, me, logAs}) ->
	selectedMembers = _.filter members, (user) -> _.some selected, (selectedID) -> selectedID is user.id
	primary =
		key: ''
		content: ''
		isDisabled: !ability.can 'create', 'User'
		onClick: => @setState activeModal: 'add'
		'data-test-id': 'toolbar-button-add-new-user'
	secondary = [
		if ((_.size selected) is 1) and (ability.can 'update', selectedMembers[0])
			key: 'edit'
			content: 'Edit'
			onClick: => @setState activeModal: 'edit'
			'data-test-id': 'toolbar-button-edit-user'
	]
	#primary: primary
	secondary: _.compact secondary

