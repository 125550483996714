import gql from 'graphql-tag'

# QUERIES
export GetOptions = gql """
	query GetUserOptions($userID: ID!, $projectsID: [ID!]) {
		projectPermissions: permissionsModel(model: Project) {
			membersRoles {
				name
				models
				displayName
			}
		}
		user(id: $userID) {
			...on Person {
				id
				name
				email
				memberships(types: [Project], ids: $projectsID) {
					...on ProjectAccess {
						project {
							id
							slug
							name
						}
						roles {
							name
						}
					}
				}
			}
		}
	}
"""

# MUTATIONS
export UpdateUser = gql '''
	mutation($userID: ID!, $addedProjects: [MemberInputAdd!], $removedProjects: [MemberInputRemove!]) {
		userUpdateProjectMembership(
			id: $userID
			addedProjects: $addedProjects
			removedProjects: $removedProjects
		)
	{
		... on Person {
			id
		}
	}
}
'''
# export UpdateUser = gql """

# 	mutation($projectID: ID!, $userID: ID!, $roles: [String!]!) {
# 		userUpdateProjectMembership
# 		# projectPersonnelUpdateRoles(
# 		# 	projectID: $projectID
# 		# 	userID: $userID
# 		# 	roles: $roles
# 		# ) {
# 		# 	... on Person {
# 		# 		id
# 		# 		projectsPermissions {
# 		# 			project {
# 		# 				id
# 		# 				slug
# 		# 				name
# 		# 			}
# 		# 			roles {
# 		# 				name
# 		# 				slug
# 		# 			}
# 		# 		}
# 		# 	}
# 		# }
# 	}
# """

