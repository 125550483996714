"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.scopes = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _helpers = require("../helpers");

var _SafetyVisit = require("./SafetyVisit");

var _SafetyObservation = require("./SafetyObservation");

var _Project = require("./Project");

var _SafetyVisitMember = require("./SafetyVisitMember");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*
SafetyVisit permissions model
*/
var scopes = (0, _helpers.makeDefaultSubject)('SafetyVisit', {
  Self: (0, _helpers.makeDefaultFields)('self', {
    Create: {
      actions: 'create'
    },
    RecordTraining: {
      actions: 'create',
      conditions: {
        recordType: 'Manual',
        type: 'Training'
      }
    },
    Read: {
      actions: 'read'
    },
    Update: {
      actions: 'update'
    },
    Delete: {
      actions: 'delete'
    },
    Destroy: {
      actions: 'destroy'
    },
    Restore: {
      actions: 'restore'
    },
    Share: {
      actions: 'share'
    }
  }),
  Members: (0, _helpers.makeDefaultFields)('members', {
    Join: {
      actions: 'join'
    },
    Leave: {
      actions: 'leave'
    },
    Add: {
      actions: 'add'
    },
    Invite: {
      actions: 'invite'
    }
  }),
  Attachments: (0, _helpers.makeDefaultFields)('attachments', {
    Create: {
      actions: 'create'
    },
    Delete: {
      actions: 'delete'
    },
    Update: {
      actions: 'update'
    },
    Manage: {
      actions: ['create', 'delete', 'update']
    }
  }),
  Review: (0, _helpers.makeDefaultFields)('review', {
    Start: {
      actions: 'start'
    },
    Cancel: {
      actions: 'cancel'
    },
    Finish: {
      actions: 'finish'
    },
    Manage: {
      actions: ['start', 'cancel', 'finish']
    }
  }),
  Type: (0, _helpers.makeDefaultFields)('type', {
    Update: {
      actions: 'update'
    }
  }),
  Guide: (0, _helpers.makeDefaultFields)('guide', {
    Update: {
      actions: 'update'
    }
  }),
  Owner: (0, _helpers.makeDefaultFields)('owner', {
    Update: {
      actions: 'update'
    }
  }),
  Progress: (0, _helpers.makeDefaultFields)('progress', {
    Start: {
      actions: 'start'
    },
    End: {
      actions: 'end'
    }
  }),
  Comments: (0, _helpers.makeDefaultFields)('comments', {
    Read: {
      actions: 'read'
    },
    Create: {
      actions: 'create'
    }
  })
});
exports.scopes = scopes;
var _default = {
  // Name of the GraphQL subject
  name: 'SafetyVisit',
  // Database collection
  collection: 'ehsVisits',
  // Name used in making global id's
  globalIDName: 'visit',
  // Available actions on root leve
  actions: [],
  // Model fields with appropiate actions
  fields: {},
  // Available scopes with implicit subject as a model.name
  scopes,
  // Additional fields requested when fetching items for attached and members roles
  projection: {
    projectID: 1,
    startedAt: 1,
    endedAt: 1,
    isDeleted: 1
  },
  // Type of models that can become a member of this model without specified role
  accepts: [],
  // Entity based roles default and assignable
  roles: {
    // Roles granted to all users
    default: [{
      name: 'Safety Visit owner',
      permissions: function ({
        user,
        parent: visit,
        membership,
        resolved,
        db,
        cache
      }) {}
    }],
    // Roles assignable to the entity that will be transfered to the User
    attached: [],
    // Roles assigned to each member
    membersDefault: [],
    // Roles assignable to the members (Users) of this entity
    members: [...(0, _helpers.makeSetOfAccepts)(['User', 'UserGroup'], [{
      name: 'Safety visit reader',
      displayName: 'Read',
      acl: true,
      inline: true,
      permissions: function ({
        user,
        parent: visit,
        membership,
        resolved,
        db,
        cache
      }) {
        return [];
      }
    }, {
      name: 'Safety visit editor',
      displayName: 'Write',
      acl: true,
      inline: true,
      permissions: function ({
        user,
        parent: visit,
        membership,
        resolved,
        db,
        cache
      }) {
        return [];
      }
    }]), ...(0, _helpers.makeSetOfAccepts)(['User'], [{
      name: 'Pending Member',
      permissions: function ({
        user,
        parent: visit,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Project.scopes.ReadSafety],
          conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'project', visit.projectID))
        }, {
          // visit.status: $in: ['InLobby']
          // visit.isDeleted: isAssociatedEntityDeletedCondition
          scopes: [_SafetyVisit.scopes.Self.Read, _SafetyVisit.scopes.Members.Leave],
          conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'visit', visit), {}, _helpers.isSelfDeletedCondition, {
            status: {
              $in: ['InLobby']
            }
          })
        }];
      }
    }, {
      name: 'Member',
      permissions: function ({
        user,
        parent: visit,
        membership,
        resolved,
        db,
        cache
      }) {
        var metaInfo;

        if (visit.isDeleted) {
          return [];
        }

        return [{
          scopes: [_Project.scopes.ReadSafety],
          conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'project', visit.projectID))
        }, {
          // visit.isDeleted: isAssociatedEntityDeletedCondition
          scopes: [_SafetyVisit.scopes.Self.Read],
          conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'visit', visit), {}, _helpers.isSelfDeletedCondition)
        }, {
          scopes: [_SafetyVisit.scopes.Members.Leave],
          conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'visit', visit), {}, _helpers.isSelfDeletedCondition, {
            status: {
              $in: ['InLobby', 'InProgress']
            }
          })
        }, {
          scopes: [_SafetyObservation.scopes.Self.Create],
          conditions: _objectSpread({}, (0, _helpers.visitAssociationCondition)(resolved, visit), {}, (0, _helpers.projectAssociationCondition)(resolved, {
            _id: visit.projectID
          }), {}, (metaInfo = {
            createdAt: {
              $gte: visit.startedAt
            }
          }, visit.endedAt != null ? metaInfo.createdAt.$lte = visit.endedAt : void 0, {
            metaInfo
          }))
        }, {
          // visit.isDeleted: isAssociatedEntityDeletedCondition
          scopes: [_SafetyObservation.scopes.Self.Read],
          conditions: _objectSpread({}, (0, _helpers.visitAssociationCondition)(resolved, visit), {}, _helpers.isSelfDeletedCondition)
        }, {
          // visit.isDeleted: isAssociatedEntityDeletedCondition
          scopes: [_SafetyObservation.scopes.Self.Delete],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.visitAssociationCondition)(resolved, visit), {
            ownerID: user._id,
            status: {
              $in: ['New']
            }
          })
        }, {
          scopes: [_SafetyObservation.scopes.Details.Update],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.visitAssociationCondition)(resolved, visit), {
            // visit.isDeleted: isAssociatedEntityDeletedCondition
            // visit.status: $in: ['InProgress']
            ownerID: user._id,
            status: {
              $in: ['New']
            }
          })
        }, {
          scopes: [_SafetyObservation.scopes.Ratings.Update],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.visitAssociationCondition)(resolved, visit), {
            // visit.isDeleted: isAssociatedEntityDeletedCondition
            // visit.status: $in: ['InProgress']
            ownerID: user._id,
            status: {
              $in: ['New']
            }
          })
        }, {
          scopes: [_SafetyObservation.scopes.Attachments.Manage],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.visitAssociationCondition)(resolved, visit), {
            // visit.isDeleted: isAssociatedEntityDeletedCondition
            // visit.status: $in: ['InProgress']
            ownerID: user._id,
            status: {
              $in: ['New']
            }
          })
        }];
      }
    }, {
      name: 'Guide',
      permissions: function ({
        user,
        parent: visit,
        membership,
        resolved,
        db,
        cache
      }) {
        var metaInfo;
        return [{
          scopes: [_Project.scopes.ReadSafety],
          conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'project', visit.projectID))
        }, {
          // visit.isDeleted: isAssociatedEntityDeletedCondition
          scopes: [_SafetyVisit.scopes.Self.Read],
          conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'visit', visit), {}, _helpers.isSelfDeletedCondition)
        }, {
          scopes: [_SafetyVisit.scopes.Self.Delete],
          conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'visit', visit), {}, _helpers.isSelfDeletedCondition)
        }, {
          // 	scopes: [
          // 		SafetyVisitScopes.Self.Share
          // 	]
          // 	conditions: {
          // 		...identityCondition resolved, 'visit',	visit
          // 		...isSelfDeletedCondition
          // 	}
          // SafetyVisitMemberScopes
          scopes: [_SafetyVisitMember.scopes.Self.Accept, _SafetyVisitMember.scopes.Self.Reject],
          conditions: _objectSpread({}, (0, _helpers.visitAssociationCondition)(resolved, visit), {
            role: 'Pending Member'
          })
        }, {
          scopes: [_SafetyVisitMember.scopes.Self.Remove],
          conditions: _objectSpread({}, (0, _helpers.visitAssociationCondition)(resolved, visit), {
            role: 'Member'
          })
        }, {
          scopes: [_SafetyVisit.scopes.Members.Add],
          conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'visit', visit), {}, _helpers.isSelfDeletedCondition)
        }, {
          scopes: [_SafetyVisit.scopes.Members.Invite],
          conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'visit', visit), {}, _helpers.isSelfDeletedCondition, {
            status: {
              $in: ['InLobby']
            }
          })
        }, {
          scopes: [_SafetyVisit.scopes.Attachments.Manage],
          conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'visit', visit), {}, _helpers.isSelfDeletedCondition, {
            status: {
              $nin: ['Resolved']
            }
          })
        }, {
          scopes: [_SafetyVisit.scopes.Review.Start],
          conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'visit', visit), {}, _helpers.isSelfDeletedCondition, {
            status: {
              $in: ['PendingReview']
            }
          })
        }, {
          scopes: [_SafetyVisit.scopes.Review.Cancel],
          conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'visit', visit), {}, _helpers.isSelfDeletedCondition, {
            status: {
              $in: ['InReview']
            }
          })
        }, {
          scopes: [_SafetyVisit.scopes.Review.Finish],
          conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'visit', visit), {}, _helpers.isSelfDeletedCondition, {
            status: {
              $in: ['InReview']
            }
          })
        }, {
          // stats.unchecked: 0
          scopes: [_SafetyVisit.scopes.Type.Update],
          conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'visit', visit), {}, _helpers.isSelfDeletedCondition, {
            status: {
              $in: ['InLobby', 'InProgress', 'PendingReview', 'InReview']
            }
          })
        }, {
          // ,
          // 	scopes: [
          // 		SafetyVisitScopes.Guide.Update ### only for 'Owner', not 'Guide'
          // 	]
          // 	conditions: {
          // 		...identityCondition resolved, 'visit',	visit
          // 		...isSelfDeletedCondition
          // 		status: $in: ['InLobby', 'InProgress', 'PendingReview', 'InReview']
          // 	}
          scopes: [_SafetyVisit.scopes.Progress.Start],
          conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'visit', visit), {}, _helpers.isSelfDeletedCondition, {
            status: {
              $in: ['InLobby']
            },
            type: {
              $exists: true
            }
          })
        }, {
          scopes: [_SafetyVisit.scopes.Progress.End],
          conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'visit', visit), {}, _helpers.isSelfDeletedCondition, {
            status: {
              $in: ['InProgress']
            }
          })
        }, {
          scopes: [_SafetyVisit.scopes.Comments.Create],
          conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'visit', visit), {}, _helpers.isSelfDeletedCondition, {
            status: {
              $in: ['ReviewCompleted', 'Resolved']
            }
          })
        }, {
          // Observations
          scopes: [_SafetyObservation.scopes.Self.Create],
          conditions: _objectSpread({}, (0, _helpers.visitAssociationCondition)(resolved, visit), {}, (0, _helpers.projectAssociationCondition)(resolved, {
            _id: visit.projectID
          }), {}, (metaInfo = {
            createdAt: {
              $gte: visit.startedAt
            }
          }, visit.endedAt != null ? metaInfo.createdAt.$lte = visit.endedAt : void 0, {
            metaInfo
          }))
        }, {
          scopes: [_SafetyObservation.scopes.Self.Read],
          conditions: _objectSpread({}, (0, _helpers.visitAssociationCondition)(resolved, visit), {}, _helpers.isSelfDeletedCondition)
        }, {
          scopes: [_SafetyObservation.scopes.Self.Delete],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.visitAssociationCondition)(resolved, visit), {
            status: {
              $in: ['New', 'PendingCheck', 'Checked']
            }
          })
        }, {
          scopes: [_SafetyObservation.scopes.Details.Update],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.visitAssociationCondition)(resolved, visit), {
            status: {
              $in: ['New', 'PendingCheck']
            }
          })
        }, {
          scopes: [_SafetyObservation.scopes.Ratings.Update],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.visitAssociationCondition)(resolved, visit))
        }, {
          scopes: [_SafetyObservation.scopes.Attachments.Manage],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.visitAssociationCondition)(resolved, visit))
        }, {
          scopes: [_SafetyObservation.scopes.Review.Check],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.visitAssociationCondition)(resolved, visit), {
            rating: {
              $ne: 'Positive'
            },
            status: 'PendingCheck'
          })
        }, {
          scopes: [_SafetyObservation.scopes.Review.Uncheck],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.visitAssociationCondition)(resolved, visit), {
            rating: {
              $ne: 'Positive'
            },
            status: 'Checked'
          })
        }];
      }
    }])]
  }
};
exports.default = _default;