"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
exports.userHubsAccess = exports.abilityFromRules = exports.subjectName = exports.models = exports.HUBS = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _ability = require("@casl/ability");

var _helpers = require("./helpers");

var _graphlib = _interopRequireWildcard(require("@dagrejs/graphlib"));

var _sift = _interopRequireDefault(require("sift"));

var _GlobalHub = _interopRequireDefault(require("./models/GlobalHub"));

var _LegalDocument = _interopRequireDefault(require("./models/LegalDocument"));

var _Organization = _interopRequireDefault(require("./models/Organization"));

var _Platform = _interopRequireDefault(require("./models/Platform"));

var _Project = _interopRequireDefault(require("./models/Project"));

var _SafetyObservation = _interopRequireDefault(require("./models/SafetyObservation"));

var _SafetyVisit = _interopRequireDefault(require("./models/SafetyVisit"));

var _User = _interopRequireDefault(require("./models/User"));

var _UserGroup = _interopRequireDefault(require("./models/UserGroup"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; if (obj != null) { var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Generated by CoffeeScript 2.4.1
var DEBUG,
    definitions,
    indexOf = [].indexOf;
DEBUG = process.env.stage === 'local';
definitions = [_GlobalHub.default, _LegalDocument.default, _Organization.default, _Platform.default, _Project.default, _SafetyObservation.default, _SafetyVisit.default, _User.default, _UserGroup.default]; // export { default as ACLSchemes } from './acl-schemes'
// export { default as Roles, definitions as rolesDefinitions } from './roles'

var HUBS = ['Costs', 'Legal', 'Personnel', 'Organizations', 'Revenue', 'Safety', 'TQT', 'WhatIf'];
exports.HUBS = HUBS;

var models = _lodash.default.reduce(definitions, function (acc, model) {
  acc[model.name] = _objectSpread({}, model, {
    accessProperty: model.accessProperty || model.name.toLowerCase(),
    // TODO: Provide calulcations based on model.fields
    searchable: model.searchable,
    roles: {
      members: _lodash.default.map(_lodash.default.get(model.roles, 'members'), function (role) {
        return _lodash.default.omit(role, 'permissions');
      }),
      attached: _lodash.default.map(_lodash.default.get(model.roles, 'attached'), function (role) {
        return _lodash.default.omit(role, 'permissions');
      })
    }
  });
  return acc;
}, {}); // Create ability from raw data
// resolved flag is used to:
//   1. Set output form of resulting conditions in client's naming convention (eg. to use *id* instead of *_id*)
//   2. Provide subject recognition for instance checks


exports.models = models;

function _default(args) {
  return new Promise(async function (resolve, reject) {
    /*
    NOTE: This is hierarchies of <BELONGS_TO> operations
    TODO: Algorithm support for self relationship e.g. Group belongs to Group
    TODO: Cyclical dependencies detection
     Current approach supports manual control over order of operations and membership in different Models
    	e.g.:
    		- User ▶ Group ▶ Legal Document
    		- User ▶ Project ▶ <Entity>
    TODO: In next releases this algorithm can be expanded to support multiple / unordered / uncontrolled levels of hierachy
    e.g.:
    	- User ▶ Group ▶ Project ▶ Legel Document ▶ ...
    */
    var _model, allUserRoles, cache, db, defaultRoles, entitiesRoles, g, i, index, j, k, l, layers, len, len1, len2, len3, len4, m, model, permissionsModels, realRoles, ref, resolved, role, rules, rulesForRoles, source, sourceModels, sources, user, userMembership;

    if (args == null) {
      return reject(new Error('Config argument missing'));
    }

    ({
      user,
      db,
      cache,
      resolved = false
    } = args);

    if (user == null) {
      return reject(new Error('User not provided for creating permission ability'));
    }

    if (db == null) {
      return reject(new Error('Database interface not provided for creating permission ability'));
    }

    permissionsModels = _lodash.default.compact(_lodash.default.map(definitions, function (model) {
      model = _lodash.default.defaultsDeep(model, {
        actions: [],
        fields: {},
        scopes: {},
        roles: {
          default: [],
          attached: [],
          members: []
        },
        acl: {}
      });

      if (_lodash.default.isEmpty(model.name)) {
        return null;
      } else {
        return _objectSpread({}, model, {
          accessProperty: model.accessProperty || model.name.toLowerCase()
        });
      }
    }));
    /*
    Internal validation of defined roles
    */

    if (DEBUG) {
      for (i = 0, len = permissionsModels.length; i < len; i++) {
        model = permissionsModels[i];
        ref = _lodash.default.get(model, 'roles.members', []);

        for (j = 0, len1 = ref.length; j < len1; j++) {
          role = ref[j];

          if (role.accepts == null || _lodash.default.isEmpty(role.accepts)) {
            throw new Error(`Model ${model.name} contains role ${role.name} without defined 'accepts' property`);
          }
        }
      }
    }

    g = new _graphlib.Graph();

    for (k = 0, len2 = permissionsModels.length; k < len2; k++) {
      _model = permissionsModels[k];
      g.setNode(_model.name);
      sourceModels = _lodash.default.compact(_lodash.default.uniq(_lodash.default.flatten([..._lodash.default.map(_lodash.default.get(_model, 'roles.members'), function (role) {
        return role.accepts || [];
      }), ...(_model.accepts || [])])));

      for (l = 0, len3 = sourceModels.length; l < len3; l++) {
        source = sourceModels[l];
        g.setEdge(source, _model.name);
      }
    }

    if (!_graphlib.default.alg.isAcyclic(g)) {
      return reject(new Error('Dependencies of models are cyclic!'));
    } // Prepare phases /layers of models to search


    index = 0;
    layers = [];

    while (!_lodash.default.isEmpty(g.nodes()) && index < 100) {
      index++;
      sources = g.sources();
      layers.push(sources);

      for (m = 0, len4 = sources.length; m < len4; m++) {
        source = sources[m];
        g.removeNode(source);
      }
    } // Inline members roles


    permissionsModels = _lodash.default.map(permissionsModels, function (model) {
      return _objectSpread({}, model, {
        roles: _objectSpread({}, model.roles, {
          default: [...model.roles.default, ..._lodash.default.filter(model.roles.members, {
            inline: true
          })],
          members: _lodash.default.reject(model.roles.members, {
            inline: true
          })
        })
      });
    });
    userMembership = [{
      id: user._id,
      model: 'User'
    }];
    defaultRoles = _lodash.default.compact(_lodash.default.flatten(_lodash.default.map(permissionsModels, function (model) {
      return model.roles.default;
    })));
    defaultRoles = _lodash.default.map(defaultRoles, function (role) {
      return {
        role
      };
    });
    entitiesRoles = _lodash.default.compact(_lodash.default.flattenDeep((await (0, _helpers.pMapSeries)(layers, function (modelsInLayer, index) {
      return new Promise(function (resolve, reject) {
        return Promise.all(_lodash.default.map(modelsInLayer, function (modelName) {
          var currentModel;
          currentModel = _lodash.default.find(permissionsModels, {
            name: modelName
          });

          if (_lodash.default.isEmpty(currentModel.collection) || _lodash.default.isEmpty(_lodash.default.get(currentModel, 'roles.members')) && _lodash.default.isEmpty(_lodash.default.get(currentModel, 'roles.attached'))) {
            return null;
          } else {
            return new Promise(async function (resolve, reject) {
              var collection, entities, err, userRoles;

              if (currentModel.name === 'User') {
                // Grab user roles from user definition
                userRoles = _lodash.default.compact(_lodash.default.map(user.roles || [], function (role) {
                  return _lodash.default.find(_lodash.default.get(currentModel, 'roles.attached'), {
                    name: role
                  });
                }));
                return resolve({
                  roles: _lodash.default.map(userRoles, function (role) {
                    return {
                      role
                    };
                  })
                });
              } else {
                try {
                  // Fetch all entities with user or entities which user is member of
                  collection = await db.collection(currentModel.collection);
                  entities = await collection.find({
                    members: {
                      $elemMatch: {
                        $or: userMembership
                      }
                    }
                  }, {
                    projection: _objectSpread({}, currentModel.projection || {}, {
                      members: 1,
                      roles: 1
                    })
                  }).toArray();
                } catch (error) {
                  err = error;
                  return reject(err);
                }

                return resolve({
                  entities: !_lodash.default.isEmpty(entities) ? {
                    id: {
                      $in: _lodash.default.map(entities, '_id')
                    },
                    model: currentModel.name
                  } : void 0,
                  roles: _lodash.default.map(entities, function (entity) {
                    var entityRoles, memberRoles;
                    entityRoles = _lodash.default.compact(_lodash.default.map(entity.roles || [], function (role) {
                      var roleToAdd;
                      roleToAdd = _lodash.default.find(_lodash.default.get(currentModel, 'roles.attached'), {
                        name: role
                      });

                      if (roleToAdd == null) {
                        console.log(`Unknown role ${role} on ${currentModel.name}/${modelName} entity ${entity._id}`);
                      }

                      return roleToAdd;
                    }));
                    memberRoles = _lodash.default.flatten(_lodash.default.compact(_lodash.default.map(_lodash.default.map(_lodash.default.filter(entity.members, (0, _sift.default)({
                      $or: userMembership
                    })), 'role'), function (role) {
                      if (role == null) {
                        return _lodash.default.get(currentModel, 'membersDefault');
                      } else {
                        return _lodash.default.find(_lodash.default.get(currentModel, 'roles.members'), {
                          name: role
                        });
                      }
                    })));
                    return _lodash.default.map([...entityRoles, ...memberRoles], function (role) {
                      return {
                        role,
                        parent: entity
                      };
                    });
                  })
                });
              }
            });
          }
        })).then(function (results) {
          userMembership = _lodash.default.compact([...userMembership, ..._lodash.default.map(results, 'entities')]);
          return resolve(_lodash.default.map(results, 'roles'));
        }).catch(function (err) {
          return reject(err);
        });
      });
    })))); // Generate rules

    allUserRoles = _lodash.default.flattenDeep([...defaultRoles, ...entitiesRoles]);
    realRoles = await Promise.all(_lodash.default.map(allUserRoles, function (roleItem) {
      return new Promise(async function (resolve, reject) {
        var err, params, permissions, roleDefinition, userMembershipForRole;
        ({
          role: roleDefinition
        } = roleItem);
        params = _lodash.default.omit(roleItem, 'role');
        permissions = await async function () {
          if (_lodash.default.isFunction(roleDefinition.permissions)) {
            try {
              userMembershipForRole = roleDefinition.accepts != null ? _lodash.default.filter(userMembership, function (membership) {
                var ref1;
                return ref1 = membership.model, indexOf.call(roleDefinition.accepts, ref1) >= 0;
              }) : userMembership;
              return await roleDefinition.permissions(_objectSpread({}, params, {}, args, {
                membership: userMembershipForRole
              }));
            } catch (error) {
              err = error;
              return console.log('Error during role permissions creation', err);
            }
          } else {
            return roleDefinition.permissions;
          }
        }();

        if (permissions != null && !_lodash.default.isArray(permissions) && _lodash.default.isPlainObject(permissions)) {
          console.warn(`Permission generator for role ${roleDefinition.name} did not return array of permissions`);
          permissions = [permissions];
        }

        return resolve(_objectSpread({}, roleDefinition, {
          permissions
        }));
      });
    }));
    rulesForRoles = _lodash.default.compact(_lodash.default.flattenDeep(_lodash.default.map(realRoles, function (role) {
      return _lodash.default.map(_lodash.default.compact(role.permissions), function ({
        scopes,
        conditions
      }) {
        return _lodash.default.map(_lodash.default.compact(_lodash.default.flattenDeep(scopes)), function (scope) {
          return _objectSpread({}, scope, {
            conditions: !_lodash.default.isEmpty(scope.conditions) && !_lodash.default.isEmpty(conditions) ? {
              $and: [scope.conditions, conditions]
            } : _objectSpread({}, conditions, {}, scope.conditions)
          });
        });
      });
    })));
    rules = [...rulesForRoles];
    return resolve(abilityFromRules(rules));
  });
}

; // Custom subjectName (exported for other Ability instances to use eg. in helpers)

var subjectName = exports.subjectName = subjectName = function (subject) {
  if (_lodash.default.isString(subject)) {
    return subject;
  } else {
    switch (subject.__typename) {
      case 'Person':
      case 'Service':
        return 'User';

      default:
        return subject.__typename;
    }
  }
}; // Create ability from permission rules


exports.subjectName = subjectName;

var abilityFromRules = exports.abilityFromRules = abilityFromRules = function (rules, resolved = false) {
  return new _ability.Ability(rules, {
    subjectName: subjectName
  });
};

exports.abilityFromRules = abilityFromRules;

var userHubsAccess = function ({
  user,
  projects,
  userAbility
}) {
  var i, len, project, userHubs;
  userHubs = [];
  userHubs = _lodash.default.reduce(HUBS, function (acc, hub) {
    if (userAbility.can('read', 'GlobalHub', hub)) {
      acc.push({
        project: null,
        hub: hub
      });
    }

    return acc;
  }, userHubs);

  for (i = 0, len = projects.length; i < len; i++) {
    project = projects[i];
    project = _objectSpread({}, project, {
      __typename: 'Project'
    });
    userHubs = _lodash.default.reduce(HUBS, function (acc, hub) {
      if (userAbility.can('read', project, hub)) {
        acc.push({
          project: project._id,
          hub: hub
        });
      }

      return acc;
    }, userHubs);
  }

  return userHubs;
};

exports.userHubsAccess = userHubsAccess;