###
Add Company Modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'

import { NotificationConsumer } from '../../../application/components/NotificationManager'
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
import { div, span } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import { Field as _Field } from '@atlaskit/form'
Field = React.createFactory _Field

import _Select, {components} from '@bevy/select'
Select = React.createFactory _Select

import _FieldText from '@atlaskit/textfield'
FieldText = React.createFactory _FieldText

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

# Styles
import styles from './index.styl'
import { info } from 'react-icons-kit/feather'

# Data
import {
	GetOptions
	UpdateUser
} from './data'

REFETCH_QUERIES = ['ProjectUsersList', 'GetUserOptions']

DataLayer = adopt
	queries:
		getOptions: ({userID, projectID}) ->
			query: GetOptions
			variables: {
				userID
				projectsID: [projectID]
			}
	mutations:
		updateUser: ({setNotification}) ->
			mutation: UpdateUser
			onCompleted: -> setNotification({content: 'User project roles successfully updated', appearance: 'success'})

emptyState = {
	roles: []
}

commonSelectProps =
	menuPortalTarget: document.body
	isSearchable: true
	hideSelectedOptions: true
	isClearable: false
	formatOptionLabel: (option) -> option.label
	getOptionLabel: (option) -> option.label
	getOptionValue: (option) -> option.value

export default class AddUserModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props
		@state = {
			...emptyState
			}

	makeUserLabel: (user) ->
		if user
			label: "#{if user.name? then user.name else '---'} (#{if user.email? then user.email else ''})"
			value: user.id

	makeRolesOptions: (arr) ->
		if !_.isEmpty arr
			_.map arr, (item) ->
				label: item.name
				value: item.name
		else
			[]

	canProceed: =>
		if (!_.isEmpty @state.roles)
			true
		else
			false

	getVariables4Mutation: (currentValues) =>
		currentRoles = _.map currentValues, 'name'
		userID: @props.userID
		addedProjects: _.map _.difference(@state.roles, currentRoles), (role) =>
			{
				id: @props.projectID
				role: role
			}
		removedProjects: _.map _.difference(currentRoles, @state.roles), (role) =>
			{
				id: @props.projectID
				role: role
			}
	onCloseProxy: (appropriateMutation, currentValues) =>
		if _.isFunction appropriateMutation
			appropriateMutation
				variables: @getVariables4Mutation(currentValues)
				refetchQueries: REFETCH_QUERIES
		@props.onClose()

	renderLabel: ({label, tooltipContent, isRequired}, children) =>
		Field
			label: span {className: styles.label},
				label
				if tooltipContent?
					div {className: cnames styles.iconTooltip, if isRequired then styles.moreMargin},
						Tooltip {content: tooltipContent}, Icon {icon: info, size: 9}
			name: label
			isRequired: isRequired
		, =>
			children

	renderTextField: ({label, slug, isRequired, tooltipContent, maxLength}) =>
		@renderLabel {label, tooltipContent, isRequired},
			div {className: styles.formItemText},
				FieldText
					className: styles.formItem
					isLabelHidden: true
					onChange: (e) => @setState ["#{slug}"]: e.target.value
					value: @state["#{slug}"]
					shouldFitContainer: true
					maxLength: maxLength

	renderSelect: ({label, slug, isRequired, tooltipContent, options, isDisabled, isMulti, defaultValue, hideSelectedOptions, components}) =>
		@renderLabel {label, tooltipContent, isRequired},
			Select {
				...commonSelectProps
				isMulti
				isDisabled
				components
				options
				defaultValue
				hideSelectedOptions
				resetMultiFormatting: true
				onChange: (item) =>
					if slug?
						if isMulti
							@setState ["#{slug}"]: _.map item, (opt) -> opt.value
						else
							@setState ["#{slug}"]: item.value
				value: _.find options, (item) => item.value is @state["#{slug}"]
			}

	MultiValueRemove: (props) =>
		if props.data.value is "Project-Leader"
			null
		else
			React.createElement components.MultiValueRemove, props

	render: ->
		UserInfoConsumer {}, ({ability, me}) =>
			NotificationConsumer {}, ({setNotification}) =>
				DataLayer
					setNotification: setNotification
					userID: @props.userID
					projectID: @props.projectID
					, (operations) =>
						{ data } = operations.getOptions
						selectedMember = if data?.user? then data.user

						BevyModal
							onClose: @onCloseProxy
							header: 'Edit user'
							actions: [
								text: 'Save'
								isDisabled: !@canProceed()
								onClick: () => @onCloseProxy operations.updateUser.mutation, selectedMemberRoles
							,
								text: 'Cancel'
								onClick: @onCloseProxy
							]
						,
							if selectedMember
								selectedMemberName = @makeUserLabel selectedMember
								# selectedMemberProjectPermissions = (_.filter data.user.projectsPermissions, (projectPermission) =>
								# 	projectPermission.project.id is @props.projectID)
								selectedMemberRoles = _.flatten _.map _.filter(data.user.memberships, (membership) => membership.project.id is @props.projectID), 'roles'
								possibleRoles = _.reject data.projectPermissions.membersRoles, {name: 'Project Leader'}
								possibleRoles = _.filter possibleRoles, (role) -> 'User' in role.models
								possibleRoles = _.sortBy possibleRoles, 'name'


								Fragment {},
									@renderSelect
										label: 'Name'
										isDisabled: true
										defaultValue: selectedMemberName
									@renderSelect
										label: 'Project roles'
										slug: 'roles'
										options: @makeRolesOptions possibleRoles
										defaultValue: @makeRolesOptions selectedMemberRoles
										components: {@MultiValueRemove}
										hideSelectedOptions: false
										isMulti: true
										tooltipContent: 'To add new user project role please contact administrator.'
