import _ from 'lodash'

import styles from '../index.styl'

export default ({data, selected}) ->
	if !_.isEmpty data.detailsQuery.data
		className: styles.toolbar
		primary:
			content: 'Add group members'
			onClick: => @setState activeModal: 'add'
		secondary: [
				key: 'delete'
				content: 'Delete group members'
				isDisabled: _.isEmpty selected
				onClick: => @setState activeModal: 'delete'
		]
