import gql from 'graphql-tag'

export RootQuery = gql """
query ProjectUsersList(
	$projectSlug: String!
	$pagination: PaginationInput
	$search: String
) {
	project(slug: $projectSlug) {
		id
		name
		members(pagination: $pagination, types: [User], search: $search) {
			...on ActorAccess {
				user {
					... on Person {
						id
						name
						email
					}
				}
				roles {
					name
				}
			}
		}
	}
}
"""

