import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import DataAttribute from '@bevy/data-attribute'

Fragment = React.createFactory React.Fragment

import _KPIToolbar from '@bevy/kpi-toolbar'
KPIToolbar = React.createFactory _KPIToolbar

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _Toolbar from '../components/Toolbar'
Toolbar = React.createFactory _Toolbar

import _FiltersBar from '../components/FiltersBar'
FiltersBar = React.createFactory _FiltersBar

import _HeaderBar from '../components/HeaderBar'
HeaderBar = React.createFactory _HeaderBar

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _DocumentHeader from '@bevy/document-header'
DocumentHeader = React.createFactory _DocumentHeader

import {
} from '@bevy/theme/icons'

export default class ContentDetails extends React.Component
	@propTypes: {}
	constructor: (props) ->
		super props
		@state = {}
	render: ->
		Fragment {},
			HeaderBar {
				document: true
				header: if @props.header? then DocumentHeader {...@props.header}
				headerColor: if @props.header.color? then @props.header.color
				kpi: if @props.kpi? then KPIToolbar {...@props.kpi}
				toolbar: if @props.toolbar? then Toolbar {...@props.toolbar}
				toolbarClassName: if @props.toolbar?.className? then @props.toolbar.className
			}
			@props.children
