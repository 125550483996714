"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.scopes = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _helpers = require("../helpers");

var _LegalDocument = require("./LegalDocument");

var _Platform = require("./Platform");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Generated by CoffeeScript 2.4.1

/*
Helpers
*/
var fetchAllPaths;
fetchAllPaths = _lodash.default.once(function (db, cache) {
  return new Promise(async function (resolve, reject) {
    var collection;
    collection = await db.collection('legalPaths');
    return resolve((await collection.find({}, {
      projection: {
        permissions: 1
      }
    }).toArray()));
  });
});

/*
UserGroup permissions model
*/
var scopes = (0, _helpers.makeDefaultSubject)('UserGroup', {
  Manage: {
    actions: 'crud'
  },
  Read: {
    actions: 'read'
  },
  Destroy: {
    actions: 'destroy'
  }
});
exports.scopes = scopes;
var _default = {
  // Name of the GraphQL subject
  name: 'UserGroup',
  // Database collection
  collection: 'userGroups',
  // Name used in making global id's
  globalIDName: 'userGroups',
  // Name used in GraphQL as AccessXXX field name
  accessProperty: 'group',
  // Available actions on root leve
  actions: ['crud', 'create', 'read', 'update', 'delete'],
  // Alias for create, read, update, delete
  // Model fields with appropiate actions
  fields: {},
  // Available scopes with implicit subject as a model.name
  scopes,
  // Additional fields requested when fetching items for attached and members roles
  projection: {},
  // Type of models that can become a member of this model without specified role
  accepts: ['User'],
  // Entity based roles default and assignable
  roles: {
    // Roles granted to all users
    default: [],
    // Roles assignable to the entity that will be transfered to the User
    attached: [{
      name: 'Skanska CDE Paths',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_LegalDocument.scopes.ReadCommited]
        }];
      }
    }, {
      name: 'Legal Paths',
      permissions: async function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        var paths;
        paths = await fetchAllPaths(db, cache);
        return [{
          scopes: [_LegalDocument.scopes.Read],
          conditions: {
            pathID: {
              $in: _lodash.default.map(_lodash.default.filter(paths, function (path) {
                return path.permissions.legal;
              }), '_id')
            }
          }
        }];
      }
    }, {
      name: 'Controlling Paths',
      permissions: async function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        var paths;
        paths = await fetchAllPaths(db, cache);
        return [{
          scopes: [_LegalDocument.scopes.ReadCommited],
          conditions: {
            pathID: {
              $in: _lodash.default.map(_lodash.default.filter(paths, function (path) {
                return path.permissions.controlling;
              }), '_id')
            }
          }
        }];
      }
    }, {
      name: 'Accounting Paths',
      permissions: async function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        var paths;
        paths = await fetchAllPaths(db, cache);
        return [{
          scopes: [_LegalDocument.scopes.ReadCommited],
          conditions: {
            pathID: {
              $in: _lodash.default.map(_lodash.default.filter(paths, function (path) {
                return path.permissions.accounting;
              }), '_id')
            }
          }
        }];
      }
    }, {
      name: 'Taxes Paths',
      permissions: async function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        var paths;
        paths = await fetchAllPaths(db, cache);
        return [{
          scopes: [_LegalDocument.scopes.ReadCommited],
          conditions: {
            pathID: {
              $in: _lodash.default.map(_lodash.default.filter(paths, function (path) {
                return path.permissions.taxes;
              }), '_id')
            }
          }
        }];
      }
    }, {
      name: 'Facility Paths',
      permissions: async function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        var paths;
        paths = await fetchAllPaths(db, cache);
        return [{
          scopes: [_LegalDocument.scopes.ReadCommited],
          conditions: {
            pathID: {
              $in: _lodash.default.map(_lodash.default.filter(paths, function (path) {
                return path.permissions.facility;
              }), '_id')
            }
          }
        }];
      }
    }, {
      name: 'Marketing Paths',
      permissions: async function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        var paths;
        paths = await fetchAllPaths(db, cache);
        return [{
          scopes: [_LegalDocument.scopes.ReadCommited],
          conditions: {
            pathID: {
              $in: _lodash.default.map(_lodash.default.filter(paths, function (path) {
                return path.permissions.marketing;
              }), '_id')
            }
          }
        }];
      }
    }, {
      name: 'TQT Paths',
      permissions: async function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        var paths;
        paths = await fetchAllPaths(db, cache);
        return [{
          scopes: [_LegalDocument.scopes.ReadCommited],
          conditions: {
            pathID: {
              $in: _lodash.default.map(_lodash.default.filter(paths, function (path) {
                return path.permissions.tqt;
              }), '_id')
            }
          }
        }];
      }
    }, {
      name: 'Leasing Paths',
      permissions: async function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        var paths;
        paths = await fetchAllPaths(db, cache);
        return [{
          scopes: [_LegalDocument.scopes.ReadCommited],
          conditions: {
            pathID: {
              $in: _lodash.default.map(_lodash.default.filter(paths, function (path) {
                return path.permissions.leasing;
              }), '_id')
            }
          }
        }];
      }
    }, {
      name: 'Asset Management Paths',
      permissions: async function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        var paths;
        paths = await fetchAllPaths(db, cache);
        return [{
          scopes: [_LegalDocument.scopes.ReadCommited],
          conditions: {
            pathID: {
              $in: _lodash.default.map(_lodash.default.filter(paths, function (path) {
                return path.permissions.assetManagement;
              }), '_id')
            }
          }
        }];
      }
    }, {
      name: 'Skanska SA Paths',
      permissions: async function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        var paths;
        paths = await fetchAllPaths(db, cache);
        return [{
          scopes: [_LegalDocument.scopes.ReadCommited],
          conditions: {
            pathID: {
              $in: _lodash.default.map(_lodash.default.filter(paths, function (path) {
                return path.permissions.skanskaSA;
              }), '_id')
            }
          }
        }];
      }
    }, {
      name: 'Architects Paths',
      permissions: async function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        var paths;
        paths = await fetchAllPaths(db, cache);
        return [{
          scopes: [_LegalDocument.scopes.ReadCommited],
          conditions: {
            pathID: {
              $in: _lodash.default.map(_lodash.default.filter(paths, function (path) {
                return path.permissions.architects;
              }), '_id')
            }
          }
        }];
      }
    }, {
      name: 'Consultant Paths',
      permissions: async function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        var paths;
        paths = await fetchAllPaths(db, cache);
        return [{
          scopes: [_LegalDocument.scopes.ReadCommited],
          conditions: {
            pathID: {
              $in: _lodash.default.map(_lodash.default.filter(paths, function (path) {
                return path.permissions.consultant;
              }), '_id')
            }
          }
        }];
      }
    }, {
      name: 'Staging environment',
      description: 'Role with access to platform in <Staging> environment',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Platform.scopes.Staging]
        }];
      }
    }, {
      name: 'Test environment',
      description: 'Role with access to platform in <Test> environment',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Platform.scopes.Test]
        }];
      }
    }, {
      name: 'Development environment',
      description: 'Role with access to platform in <Development> environment',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Platform.scopes.Development]
        }];
      }
    }],
    // Roles assignable to the members (Users) of this entity
    members: []
  }
};
exports.default = _default;