import gql from 'graphql-tag'

export RootQuery = gql """
	query ProjectDashboard ($projectSlug: String!, $documentsFilters: LegalDocumentsFilters){
		project(slug: $projectSlug) {
			id
			name
			slug
			city
			country
			street
			buildingNumber
			postalCode
			dates { type value }
			code
			photo {
				original
			}
			spv {
				id
				name
				abbreviation
				type
				website
				hedquarters {
					street
					buildingNumber
					postalCode
					city
				}
				nip
				regon
				krs
				description
				representationDescription
				representationType
				}
			photo {original}
			location {coordinates}
			legalDocuments(filters: $documentsFilters) {
				id
				topic { name }
				form { name }
				identifiers {type value}
				state
			}
			projectManagement {
				type
				roles
			}
			userGroups {
				id
				name
				slug
			}
			members {
				...on ActorAccess {
					roles {
						name
					}
					user {
						...on Person {
							id
							name
							email
							position
							telephone
							picture {
								original
								mini
							}
							memberships(types: [UserGroup]) {
								...on UserGroupAccess {
									group {
										id
										name
									}
								}
							}
						}
					}
				}
			}
		}
	}
"""
