import gql from 'graphql-tag'

# QUERIES
export GetNotMembers = gql """
	query GetNotMembers(
		$projectID: ID!,
		$pagination: PaginationInput,
		$search: String
	) {
		projectByID(id: $projectID) {
			id
			notMembers (pagination: $pagination, search: $search, types: [User]){
				...on Person {
					id
					name
					email
				}
			}
		}
	}
"""

export GetRoles = gql """
	query GetRoles {
		permissionsModel(model: Project) {
			membersRoles {
				name
				models
				displayName
			}
		}
	}
"""

# MUTATIONS
export UpdateUser = gql """
	mutation($userID: ID!, $addedProjects: [MemberInputAdd!]) {
		userUpdateProjectMembership(
			id: $userID
			addedProjects: $addedProjects
		)
	{
		... on Person {
			id
		}
	}
}
"""

