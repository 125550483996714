exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1-Dk1nIh6CmhcElDfLZxJg {\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  overflow: hidden;\n}\n._1-Dk1nIh6CmhcElDfLZxJg ._3q8tNtS__BTwl6wP2_6zO6 {\n  width: 250px;\n  border-right: 1px solid #dfe1e6;\n  overflow-y: scroll;\n  position: relative;\n}\n._1-Dk1nIh6CmhcElDfLZxJg ._3q8tNtS__BTwl6wP2_6zO6 > a {\n  text-decoration: inherit;\n  color: inherit;\n}\n._1-Dk1nIh6CmhcElDfLZxJg ._3HLf9UhH1JWl0tosV8mmWj {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  overflow: hidden;\n}\n._1-Dk1nIh6CmhcElDfLZxJg ._3HLf9UhH1JWl0tosV8mmWj ._2G3ZmJF_ADucC8EJfPN3QL {\n  flex: 1;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n}\n._1-Dk1nIh6CmhcElDfLZxJg ._3HLf9UhH1JWl0tosV8mmWj:not(.eXr0RwTeYjKyfiw2POrpZ) {\n  background-color: #ebecf0;\n}\n._1-Dk1nIh6CmhcElDfLZxJg ._3HLf9UhH1JWl0tosV8mmWj:not(.eXr0RwTeYjKyfiw2POrpZ) ._2G3ZmJF_ADucC8EJfPN3QL {\n  border-top: 1px solid #ebecf0;\n  background: #fff;\n  position: relative;\n}\n._1-Dk1nIh6CmhcElDfLZxJg ._3HLf9UhH1JWl0tosV8mmWj.eXr0RwTeYjKyfiw2POrpZ {\n  flex: 1;\n}\n._1-Dk1nIh6CmhcElDfLZxJg ._3HLf9UhH1JWl0tosV8mmWj ._343L_c_RBNWO0nMNA9rrHK {\n  background: #fff;\n  flex: 1;\n  overflow-y: scroll;\n}\n._2UPO7mmI4IaJmwyrEbE0dT {\n  padding: 8px 8px 4px 8px;\n  position: sticky;\n  top: 0;\n  background: #fafbfc;\n  z-index: 1;\n  border-bottom: 1px solid #dfe1e6;\n  display: flex;\n  flex-direction: column;\n  color: #091e42;\n}\n._2UPO7mmI4IaJmwyrEbE0dT ._2iZM2td9WZIC_ql9ljtbdT {\n  display: flex;\n  justify-content: space-between;\n  font-size: 0.82rem;\n}\n._2UPO7mmI4IaJmwyrEbE0dT .wykzCXVlxpG44QnY-TeKD {\n  display: flex;\n}\n._2UPO7mmI4IaJmwyrEbE0dT .ZXDZM041lZTffoyGhv6qb {\n  margin: 4px 0 0;\n  font-weight: 500;\n  max-width: 85%;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n._2UPO7mmI4IaJmwyrEbE0dT ._3D4amZ3vMuv3flQa1kH8XQ {\n  margin-left: auto;\n  cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"masterDetail": "_1-Dk1nIh6CmhcElDfLZxJg",
	"list": "_3q8tNtS__BTwl6wP2_6zO6",
	"detail": "_3HLf9UhH1JWl0tosV8mmWj",
	"detailWrapper": "_2G3ZmJF_ADucC8EJfPN3QL",
	"detailFull": "eXr0RwTeYjKyfiw2POrpZ",
	"detailContent": "_343L_c_RBNWO0nMNA9rrHK",
	"MasterDetailGroup": "_2UPO7mmI4IaJmwyrEbE0dT",
	"topBar": "_2iZM2td9WZIC_ql9ljtbdT",
	"bottomBar": "wykzCXVlxpG44QnY-TeKD",
	"subtitle": "ZXDZM041lZTffoyGhv6qb",
	"chevron": "_3D4amZ3vMuv3flQa1kH8XQ"
};