###
Button component with additional functionalties:
- tooltip info
- linking functionality with react router

Component uses https://atlaskit.atlassian.com/packages/core/button as base implemenetation

TODO: Button should support data-test-id by default
TODO!: Better support for disabled attribute on link
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import DataAttribute from '@bevy/data-attribute'
import { a as NativeLink } from 'react-dom-factories'
# Renderable
import {Link as _Link} from 'react-router-dom'
Link = React.createFactory _Link

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

import _ButtonElement from '@atlaskit/button'
ButtonElement = React.createFactory _ButtonElement

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon
# Styles
import styles from './index.styl'

###
Wip
###
import { div } from 'react-dom-factories'


# TODO: <NCU-0> After dependencies this feature needs to be re-checked
import {
	DropdownMenuStateless as _DropdownMenuStateless
} from '@atlaskit/dropdown-menu'
DropdownMenuStateless = React.createFactory _DropdownMenuStateless

import {
	chevronDown
} from '@bevy/theme/icons'

# ButtonPure supports basic functionalities like tooltip, link
export class ButtonPure extends React.Component
	constructor: (props) ->
		super props
	render: ->
		content = ButtonElement {
			..._.omit @props, ['tooltip', 'appearance', 'destructive']
			className: cnames [
				@props.className
				if @props.appearance? and @props.appearance is 'toolbar-action-primary' then styles.primaryAction
				if @props.appearance? and @props.appearance is 'toolbar-action' then styles.toolbarAction
				if @props.destructive? and @props.destructive then styles.destructive
			]
			appearance: do =>
				if @props.appearance?
					if @props.appearance in ['toolbar-action-primary', 'toolbar-action']
						'subtle'
					else if @props.appearance in ['default', 'danger', 'link', 'primary', 'subtle', 'subtle-link', 'warning']
						@props.appearance
					else
						'subtle'
			component: do =>
				if @props.href?
					linkComponent = Link
					try
						url = new URL @props.href
						if url.hostname isnt window.location.hostname
							linkComponent = NativeLink

					(props) =>
						{className, style, children, href, target} = props
						if @props.component?
							linkComponent {className: cnames [ styles.internalLink, if @props.isDisabled then styles.disabled ], to: href, target: target},
								@props.component {
									..._.omit(props, 'destructive')
								}, children
						else
							linkComponent {
								..._.omit(props, 'destructive')
								to: href
								target: target
								className: cnames [props.className, if @props.isDisabled then styles.disabled]
							}, children
				else if @props.component?
					@props.component
		}
		if @props['data-test-id']?
			content = div {['data-test-id']: DataAttribute @props['data-test-id']}, content
		if @props.tooltip?
			Tooltip {
				content: @props.tooltip
			},
				content
		else
			content

# ButtonSplit adds support for additional dropdown
export class ButtonSplit extends React.Component
	@propTypes:
		addonClassName: PropTypes.string
	constructor: (props) ->
		super props
	render: ->
		div {className: styles.ButtonSplit},
			ButtonPureElement {
				..._.omit @props, ['options', 'iconAfter']
				className: cnames @props.className, styles.main
				# icon: chevronDown
			}
			ButtonDroplistElement {
				..._.omit @props, ['iconBefore', 'className', 'onClick', 'onDoubleClick', 'tooltip']
				iconAfter: if @props.iconAfter? then @props.iconAfter else Icon chevronDown
				className: cnames @props.addonClassName, styles.addon
			}

# TODO!: Outside inside clicking should be extracted to some component!
export class ButtonDroplist extends React.Component
	@propTypes:
		isOpen: PropTypes.bool
		wrapperClassName: PropTypes.string
		droplistClassName: PropTypes.string
	constructor: (props) ->
		super props
		@root = React.createRef()
		@innerRoot = React.createRef()
		@state =
			isOpen: @props.isOpen || false
	componentWillUnmount: ->
		if @state.isOpen
			@unmountListeners()
	mountListeners: =>
		document.addEventListener 'click', @handleClick
		document.addEventListener 'scroll', @handleScroll
		document.addEventListener 'keydown', @handleEsc
	unmountListeners: =>
		document.removeEventListener 'click', @handleClick
		document.removeEventListener 'scroll', @handleScroll
		document.removeEventListener 'keydown', @handleEsc
	handleClick: (event) =>
		return null if !@root?.current?
		wasOutside = !(@root.current.contains event.target)
		wasInside = (@innerRoot.current.contains event.target)
		if wasOutside && @state.isOpen
			@tryClosing()
		else if !wasOutside && !wasInside && @state.isOpen
			@tryClosing()
	handleScroll: (event) =>
		wasOutside = !(@root.current.contains event.target)
		if wasOutside && @state.isOpen
			@tryClosing()
	handleEsc: (event) =>
		if event.key is 'Escape'
			@tryClosing()
	tryClosing: ->
		if @config.requestClose
			@config.onRequestClose()
		else
			@close()
	open: (event, content, config) =>
		event.preventDefault()
		@mountListeners()

		@event = event
		@content = content
		@config = _.defaults config,
			requestClose: false
			onRequestClose: ->
			onClose: ->

		@setState
			isOpen: true
			clickPosition:
				x: event.clientX
				y: event.clientY
	close: =>
		if @state.isOpen
			@unmountListeners()
			@setState
				isOpen: false
				clickPosition: null
			@config.onClose()
	render: ->
		div {
			ref: @root
			className: cnames [
				styles.ButtonDroplist
				@props.wrapperClassName
				if @state.isOpen then styles.open
			]
		},
			# TODO: <NCU-0> After dependencies this feature needs to be re-checked
			DropdownMenuStateless
				isOpen: @state.isOpen
				position: @props.position
				trigger: ButtonPureElement {
					..._.omit @props, ['isOpen', 'options', 'position']
					isSelected: @state.isOpen
					onClick: @open
				}
			,
				div { ref: @innerRoot, className: cnames styles.droplist, @props.droplistClassName }, @props.options


ButtonPureElement = React.createFactory ButtonPure
ButtonSplitElement = React.createFactory ButtonSplit
ButtonDroplistElement = React.createFactory ButtonDroplist

export {ButtonGroup} from '@atlaskit/button'

export default class ButtonExtra extends React.Component
	@propTypes:
		tooltip: PropTypes.node
		href: PropTypes.string
		appearance: PropTypes.oneOf [
			'default'
			'danger'
			'link'
			'primary'
			'subtle'
			'subtle-link'
			'warning'
			'help'
			'toolbar-action-primary' # Equals to subtle + primary color for text
			'toolbar-action'
		]
		destructive: PropTypes.bool
		target: PropTypes.oneOfType [
			PropTypes.oneOf [
				'_blank'
				'_self'
				'_parent'
				'_top'
			]
			PropTypes.string
		]
		options: PropTypes.node
	@defaultProps:
		destructive: false

	render: ->
		# Decision about what type of button we should render
		if (!@props.onClick? && !@props.onDoubleClick?) && @props.options?
			ButtonDroplistElement @props
		else if (@props.onClick? || @props.onDoubleClick?) && @props.options?
			ButtonSplitElement @props
		else
			ButtonPureElement @props



