import _ from 'lodash'
import React from 'react'

import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import {
	add
} from '@bevy/theme/icons'
import styles from '../index.styl'

export default ({selected, ability}) ->

	primary =
		key: 'add'
		content: 'Add group'
		iconBefore: Icon add
		# isDisabled: true
		onClick: => @setState activeModal: 'add'
		'data-test-id': 'toolbar-button-add-new-group'
	secondary = [
		if ((_.size selected) is 1)
			key: 'edit'
			content: 'Edit'
			# isDisabled: true
			onClick: => @setState activeModal: 'edit'
			'data-test-id': 'toolbar-button-edit-group'
	,
		# if  ((_.size selected) is 1)
			key: 'delete'
			content: 'Delete'
			# isDisabled: true
			onClick: => @setState activeModal: 'delete'
			'data-test-id': 'toolbar-button-delete-group'
	]
	primary: primary
	secondary: _.compact secondary

