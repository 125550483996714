"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.scopes = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _helpers = require("../helpers");

var _GlobalHub = require("./GlobalHub");

var _Project = require("./Project");

var _Organization = require("./Organization");

var _LegalDocument = require("./LegalDocument");

var _SafetyVisit = require("./SafetyVisit");

var _SafetyObservation = require("./SafetyObservation");

var _Platform = require("./Platform");

var _UserGroup = require("./UserGroup");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Generated by CoffeeScript 2.4.1
var UserScopes;

/*
User permissions model
*/
var scopes = (0, _helpers.makeDefaultSubject)('User', {
  Manage: {
    actions: 'crud'
  },
  Create: {
    actions: 'create'
  },
  Update: {
    actions: 'update'
  },
  UpdateInfo: {
    actions: 'update',
    fields: 'info'
  },
  Read: {
    actions: 'read'
  },
  Delete: {
    actions: 'delete'
  },
  LogAs: {
    actions: 'logAs'
  }
});
exports.scopes = scopes;
UserScopes = scopes;
var _default = {
  // Name of the GraphQL subject
  name: 'User',
  // Database collection
  collection: 'users',
  // Name used in making global id's
  // TODO: Service vs User for global id
  globalIDName: 'user',
  // Available actions on root leve
  actions: ['crud', 'create', 'read', 'update', 'delete'],
  // Alias for create, read, update, delete
  // Model fields with appropiate actions
  fields: {},
  // Searchable properties, in GraphQL as a full text search
  // NOTE: This information should be available / calculated based on <fields> property
  searchable: ['givenName', 'surname', 'mail'],
  // Available scopes with implicit subject as a model.name
  scopes,
  // Additional fields requested when fetching items for attached and members roles
  projection: {},
  // Type of models that can become a member of this model without specified role
  accepts: [],
  // Entity based roles default and assignable
  roles: {
    // Roles granted to all users
    default: [{
      name: 'Self',
      description: 'Self-awareness role of each user',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [UserScopes.Read, UserScopes.UpdateInfo],
          conditions: resolved ? {
            'id': (0, _helpers.makeGlobalID)('user', user._id)
          } : {
            '_id': user._id
          }
        }];
      }
    }],
    // Roles assignable to the entity that will be transfered to the User
    attached: [{
      name: 'Platform admin',
      description: 'Administrator of platform',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_GlobalHub.scopes.ReadAll, UserScopes.Read, UserScopes.LogAs, _Project.scopes.Update, _Project.scopes.Create, _Organization.scopes.Delete, _UserGroup.scopes.Manage, UserScopes.Manage]
        }];
      }
    }, {
      name: 'Platform superadmin',
      description: 'God-like administrator of platform',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_GlobalHub.scopes.ReadAll, UserScopes.Manage, UserScopes.LogAs, _Project.scopes.Update, _Project.scopes.Create, _LegalDocument.scopes.UpdateCommited, _Organization.scopes.Delete, _UserGroup.scopes.Manage, UserScopes.Manage, _Project.scopes.ReadAllHubs, _SafetyVisit.scopes.FullManage, _SafetyObservation.scopes.FullManage]
        }];
      }
    }, {
      name: 'Personnel Manager',
      description: 'Manages users in platform',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_GlobalHub.scopes.ReadAll, UserScopes.Manage]
        }];
      }
    }, {
      // UserScopes.LogAs
      name: 'Safety Manager',
      description: '⛑Manager of all safety properties in all projects',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {}
    }, {
      name: 'Safety Manager RD',
      description: '⛑Manager of all safety properties in all RD based projects',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {}
    }, {
      name: 'Safety Manager CDE',
      description: '⛑Manager of all safety properties in all CDE based projects',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {}
    }, {
      name: 'Staging environment tester',
      description: 'Role with access to platform in <Staging> environment',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Platform.scopes.Staging]
        }];
      }
    }, {
      name: 'Test environment tester',
      description: 'Role with access to platform in <Test> environment',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Platform.scopes.Test]
        }];
      }
    }, {
      name: 'Development environment tester',
      description: 'Role with access to platform in <Development> environment',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Platform.scopes.Development]
        }];
      }
    }],
    // Roles assignable to the members (Users) of this entity
    members: []
  }
};
exports.default = _default;