import gql from 'graphql-tag'

export RootQuery = gql """
query ($pagination: PaginationInput, $search: String){
		users (
			pagination: $pagination
			search: $search
				){
				... on Service {
						id
				}
				... on Person {
						id
						name
						email
						department
						position
						telephone
						memberships {
							...on UserGroupAccess {
								group {
									id
									name
								}
								roles {
										name
								}
							}
							...on ProjectAccess {
								project {
									id
									name
								}
								roles {
									name
								}
							}
					}
					roles {
						name
					}
				}
		}
		usersCount
}
"""

