import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'

# Renderables
import { div, span, p } from 'react-dom-factories'

# Data
import trLegal from 'data/local/legal'

# Styles
import styles from './columns.styl'
import {
	userCheck
} from 'react-icons-kit/feather'

Fragment = React.createFactory React.Fragment

import _Badge from '@atlaskit/badge'
Badge = React.createFactory _Badge

name =
	key: 'name'
	label: 'Name'
	sortKey: 'Name'
	width: 20
	isSortable: true
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.name? then item.name else '-'

email =
	key: 'email'
	label: 'E-mail'
	sortKey: 'Email'
	width: 20
	isSortable: true
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.email? then item.email else '-'

groups =
	key: 'groups'
	label: 'Groups'
	width: 200
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if (!_.isEmpty _.filter item.memberships, 'group')
				groups = _.filter item.memberships, 'group'
				Fragment {},
					_.join (_.map (_.slice groups, 0, 3), (group) -> group.group.name), ', '
					if (_.size groups) > 3
						Fragment {},
							Fragment {}, ' '
							Badge {},
								span {className: styles.fontBadge}, '+' + ((_.size groups) - 3)
			else '-'

globalRoles =
	key: 'roles'
	label: 'Global roles'
	width: 200
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if !_.isEmpty item.roles
				Fragment {},
					_.join (_.map (_.slice item.roles, 0, 3) , (role) -> role.name), ', '
					if (_.size item.roles) > 3
						Fragment {},
							Fragment {}, ' '
							Badge {},
								span {className: styles.fontBadge}, '+' + ((_.size item.roles) - 3)
			else '-'
			#if !_.isEmpty item.roles then _.join (_.map item.roles, (role) -> role.name), ', ' else '-'

projects =
	key: 'projects'
	label: 'Projects'
	width: 200
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if (!_.isEmpty _.filter item.memberships, 'project')
				projects = _.filter item.memberships, 'project'
				Fragment {},
					_.join (_.map (_.slice projects, 0, 3), (project) -> project.project.name), ', '
					if (_.size projects) > 3
						Fragment {},
							Fragment {}, ' '
							Badge {},
								span {className: styles.fontBadge}, '+' + ((_.size projects) - 3)
			else '-'

export USERS_COLUMNS = do ->
	columns =
		[
			{ ...name, selectedByDefault: true }
			{ ...email, selectedByDefault: true}
			{ ...globalRoles, selectedByDefault: true}
			{ ...groups, selectedByDefault: true}
			{ ...projects, selectedByDefault: true}
		]
	_.map columns, (column) -> {...column, selected: column.selectedByDefault}
