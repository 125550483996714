###
Project specific links and tools
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import { matchPath} from 'react-router-dom'
import gql from 'graphql-tag'
# Renderable
import { div, p, span, a } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Avatar from '@atlaskit/avatar'
Avatar = React.createFactory _Avatar

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _DropdownMenu, { DropdownItem as _DropdownItem} from '@atlaskit/dropdown-menu'
DropdownMenu = React.createFactory _DropdownMenu
DropdownItem = React.createFactory _DropdownItem

import _Badge from '@atlaskit/badge'
Badge = React.createFactory _Badge

import {Link as _Link} from 'react-router-dom'
Link = React.createFactory _Link

import {InternalErrorBanner as _InternalErrorBanner} from 'components/ErrorDisplay'
InternalErrorBanner = React.createFactory _InternalErrorBanner

import { UserInfoConsumer } from 'libs/userInfo'

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import getModal from './../ShortcutModal'

# Styles
import {
	book # summary
	briefcase # leasing
	trendingUp # modelling
	shield # safety
	users # personnel
	checkCircle # quality controll
	dollarSign # costs
	fileText # documents
	chevronDown
	chevronUp
	mapPin
	plus
	trash2
	edit2
	externalLink
} from 'react-icons-kit/feather'
import styles from './index.styl'

# Data
import trLegal from 'data/local/legal'
import { RootQuery } from './data'

export class ProjectItem extends React.Component
	@propTypes:
		interactive: PropTypes.bool
		after: PropTypes.func
		navigate: PropTypes.func
		project: PropTypes.shape
			photo: PropTypes.shape
				mini: PropTypes.string
			id: PropTypes.string
			slug: PropTypes.string
			name: PropTypes.string
			city: PropTypes.string
			country: PropTypes.string
	render: ->
		div {
			className: [
				styles.projectItem
				if @props.interactive then styles.interactive
			].join ' '
		},
			if @props.project?
				Fragment {},
					Avatar
						component: ({children, className}) ->
							div {
								style:
									pointerEvents: 'none'
								className: className
							}, children
						src: if @props.project.photo?.mini? then @props.project.photo.mini
						appearance: 'square'
					div {className: styles.description},
						p {className: styles.text}, "#{@props.project.name}"
						p {className: styles.subText},
							if @props.project.city? and @props.project.country?
								"#{@props.project.city}, #{@props.project.country}"
					do =>
						if @props.after?
							div {className: styles.after},
								@props.after()
			else
				Fragment {},
					Avatar
						component: ({children, className}) ->
							div {
								style:
									pointerEvents: 'none'
								className: className
							}, children
						appearance: 'square'
					div {className: styles.label}, "Select project"
					do =>
						if @props.after?
							div {className: styles.after},
								@props.after()

_ProjectItem = React.createFactory ProjectItem

export default class ProjectNavigation extends React.Component
	constructor: (props) ->
		super props
		@state =
			activeModal: null

	@propTypes:
		project: PropTypes.string

	getModal: getModal
	matchPath: (path, exact = true) ->
		match = matchPath window.location.pathname,
			path: path
			exact: exact
		match?

	handleDMSCount: (project) ->
		if (project.inboxUrgentCount? and project.inboxUrgentCount isnt 0) or (project.registryUrgentCount? and project.registryUrgentCount isnt 0)
			appearance: 'important'
			count: project.inboxUrgentCount + project.registryUrgentCount
		else
			appearance: 'primary'
			count: project.inboxFreshCount + project.registryFreshCount

	renderItem: (item, ability) ->
		component = if item.type is 'shortcut' then a else Link
		component {
			className: styles.itemLink, 
			to: if item.type is 'shortcut' then null else item.href, 
			key: item.key, 
			onClick: if item.type is 'shortcut' then null else @props.onClick, 
			href: item.href, 
			target: if item.type is 'shortcut' then '_blank' else null
			},
			div {className: cnames styles.item, if item.isSelected then styles.selectedItem},
				Icon {className: styles.itemIcon, icon: item.icon, size: 20}
				div {},
					item.text
					if item.description
						div {className: styles.itemDescription}, item.description
				if item.count? and item.count isnt 0
					div {className: styles.badge},
						Badge {appearance: if item.badgeAppearance? then item.badgeAppearance else 'primary'},
							item.count
				if item.type is 'shortcut' and ability.can 'update', 'Project'
					Fragment {}, 
						Button {
							iconBefore: Icon {icon: edit2, size:16}
							className: styles.itemIconEdit
							onClick: (e) => 
								e.preventDefault()
								e.stopPropagation()
								@setState 
									activeModal: "edit"
									shortcutID: item.id
						}
						Button {
							iconBefore: Icon {className: styles.itemIconRed, icon: trash2, size:16}
							onClick: (e) => 
								e.preventDefault()
								e.stopPropagation()
								@setState 
									activeModal: "delete"
									shortcutID: item.id
						}
	renderNavigation: ({data, loading, ability}) ->
		if data.project?
			applications = @props.projectApplications data.project
			sections = _.compact [
					key: 'summary'
					items: [
						type: 'item'
						text: 'Summary'
						key: 'summary'
						icon: book
						isSelected: @matchPath '/projects/:project'
						href: "/projects/#{@props.project}"
					]
				,
					type: 'group'
					key: 'operations'
					text: 'Operations'
					items: _.filter applications, group: 'operations'
				,
					type: 'group'
					key: 'supervision'
					text: 'Supervision'
					items: _.filter applications, group: 'supervision'
				,
					type: 'group'
					key: 'misc'
					text: 'Details'
					items: _.map _.filter(applications, group: 'misc'), (item) =>
						if item.key isnt 'legal'
							item
						else
							{
								...item
								...@handleDMSCount data.project
							}
				,
					if (!_.isEmpty data.project.navigationShortcuts)
							type: 'shortcuts'
							key: 'shortcuts'
							text: 'Shortcuts'
							items: _.map data.project.navigationShortcuts, (shortcut) ->
								type: 'shortcut'
								text: shortcut.name
								key: shortcut.name
								icon: externalLink
								isSelected: false
								href: shortcut.url
								id: shortcut.id
				]
		div {className: styles.sectionsWrapper},
			_.map (_.reject sections, (s) -> _.isEmpty s.items), (section) =>
				div {className: styles.section, key: section.key},
					_.map section.items, (item) =>
						@renderItem(item, ability)

	renderButtonToAddUrl: ({data, loading, ability}) =>
		if ability.can 'update', 'Project'
			div {className: styles.sectionsWrapper},
				div
					className: styles.itemLink, 
					onClick: () => @setState activeModal: 'add'
					,
					div {className: cnames styles.item},
						Icon {className: styles.itemIcon, icon: plus, size: 20}
						div {}, "Add shortcut"

	renderSelector: ({data, loading, ability}) ->
		otherProjects = _.reject(data.projects, slug: data.project?.slug)
		div {className: styles.ProjectSelector},
			if _.isEmpty otherProjects
				_ProjectItem {
					project: _.find data.projects, slug: data.project?.slug
				}
			else
				DropdownMenu
					shouldFitContainer: true
					isLoading: loading
					trigger: do ->
						if !loading
							project = _.find data.projects, slug: data.project?.slug
							_ProjectItem {
								project
								interactive: true
								after: ->
									Icon icon: chevronDown, size: 28
							}
				,
					if _.isEmpty otherProjects
						span {className: styles.emptyList}, 'no other available projects'
					else
						div {className: styles.dropdownContent},
							_.map otherProjects, (project) =>
								DropdownItem {
									key: project.id
									onClick: (event) =>
										@props.history.push "/projects/#{project.slug}"
								},
									_ProjectItem
										project: project

	render: ->
		UserInfoConsumer {}, ({ability, me}) =>
			Query
				query: RootQuery
				variables:
					projectSlug: @props.project
					inboxUrgentFilters: {isUrgent: true, hasApproval: false}
					inboxFreshFilters: {isFresh: true, hasApproval: false}
					registryUrgentFilters: {isUrgent: true, hasApproval: true}
					registryFreshFilters: {isFresh: true, hasApproval: true}
				errorPolicy: 'all'
			, (queryResultat) =>
				div {className: styles.ProjectNavigation},
					if queryResultat.loading
						Spinner {}
					else if !queryResultat.data?
						InternalErrorBanner {}
					else
						Fragment {},
							@renderSelector {...queryResultat, ability}
							@renderNavigation {...queryResultat, ability}
							@renderButtonToAddUrl {...queryResultat, ability}
							@getModal {
								...queryResultat
								onClose: () =>
									@setState
										activeModal: null
										shortcutID: null
								}
