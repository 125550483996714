
###
Platform global navigation
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { matchPath, Link as _Link } from 'react-router-dom'
Link = React.createFactory _Link
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
import { div, button } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _ProjectNavigation from './components/ProjectNavigation'
ProjectNavigation = React.createFactory _ProjectNavigation

import _SearchPanel from './components/SearchPanel'
SearchPanel = React.createFactory _SearchPanel

import _UserPanel from './components/UserPanel'
UserPanel = React.createFactory _UserPanel

import _ApplicationSwitcher from './components/ApplicationSwitcher'
ApplicationSwitcher = React.createFactory _ApplicationSwitcher

import _ProjectIndicator from './components/ProjectIndicator'
ProjectIndicator = React.createFactory _ProjectIndicator

import _GlobalNav from '@bevy/global-navigation'
GlobalNav = React.createFactory _GlobalNav

import trLegal from 'data/local/legal'

# Styles
import {
	# grid
	# menu
	# bell
	# helpCircle
	# link
	# search
	# home
	# briefcase
	# alignJustify
	# fileText
	# settings
	# user

	book # summary
	briefcase # leasing
	trendingUp # modelling
	shield # safety
	users # personnel
	checkCircle # quality controll
	dollarSign # costs
	fileText # documents
	chevronDown
	chevronUp
	mapPin

	search
	bell
	helpCircle
	settings
	user
} from 'react-icons-kit/feather'
import {
	ic_apps
} from 'react-icons-kit/md'
import styles from './index.styl'

export default class GlobalNavigation extends React.Component
	constructor: (props) ->
		super props
		@state =
			appSwitcherSize: 'wide'
	matchPath: (path, exact = true) ->
		match = matchPath window.location.pathname,
			path: path
			exact: exact
		match?
	projectApplications: (ability) =>
		(project) =>
			apps = _.compact [
					if ability.can 'read', project, 'Revenue'
						group: 'operations'
						type: 'item'
						key: 'revenue'
						text: 'Revenue'
						icon: briefcase
						isSelected: @matchPath '/projects/:project/revenue', false
						href: "/projects/#{project.slug}/revenue"
				,
					if ability.can 'read', project, 'Costs'
						group: 'operations'
						type: 'item'
						key: 'costs'
						text: 'Costs'
						icon: dollarSign
						isSelected: @matchPath '/projects/:project/costs', false
						href: "/projects/#{project.slug}/costs"
				,
					if ability.can 'read', project, 'WhatIf'
						group: 'operations'
						type: 'item'
						key: 'modelling'
						text: 'What-if'
						icon: trendingUp
						isSelected: @matchPath '/projects/:project/scenarios', false
						href: "/projects/#{project.slug}/scenarios"
				,
					if ability.can 'read', project, 'Safety'
						group: 'supervision'
						type: 'item'
						key: 'ehs'
						text: 'Safety'
						icon: shield
						isSelected: @matchPath '/projects/:project/safety', false
						href: "/projects/#{project.slug}/safety"
				,
					if ability.can 'read', project, 'TQT'
						group: 'supervision'
						type: 'item'
						key: 'quality'
						text: 'Technical quality'
						icon: checkCircle
						isSelected: @matchPath '/projects/:project/quality', false
						href: "/projects/#{project.slug}/quality"
				,
					if ability.can 'read', project, 'Legal'
						group: 'misc'
						type: 'item'
						key: 'legal'
						text: trLegal.global.module
						icon: fileText
						isSelected: @matchPath '/projects/:project/legal', false
						href: "/projects/#{project.slug}/legal"
						# badgeAppearance: appearance
				,
					if ability.can 'read', project, 'Personnel'
						group: 'misc'
						type: 'item'
						key: 'users'
						text: 'Users'
						icon: users
						isSelected: @matchPath '/projects/:project/users', false
						href: "/projects/#{project.slug}/users"
				,
					if ability.can 'read', project, 'Organizations'
						group: 'misc'
						type: 'item'
						key: 'companies'
						text: 'Companies'
						icon: mapPin
						isSelected: @matchPath '/projects/:project/companies', false
						href: "/projects/#{project.slug}/companies"
			]
			apps



	render: ->
		UserInfoConsumer {}, ({ability, me}) =>
			GlobalNav
				menuClassName:
					switch __STAGE__
						when 'local'
							styles.MenuLocal
						when 'development'
							styles.MenuDevelopment
						when 'test'
							styles.MenuTest
						when 'staging'
							styles.MenuStaging
				primaryItems: _.compact [
						id: 'app-switcher'
						openAs: "drawer-#{@state.appSwitcherSize}"
						name: 'Application switcher'
						tooltip: 'Quickly jump between application and recent activities in S360 platform'
						icon: Icon
							icon: ic_apps
							size: 18
						content: ({close}) =>
							ApplicationSwitcher
								projectApplications: @projectApplications(ability)
								onExpand: => @setState appSwitcherSize: 'extended'
								onCollapse: => @setState appSwitcherSize: 'wide'
								close: close
					,
						id: 'search'
						openAs: 'drawer-wide'
						name: 'Search'
						tooltip: 'Search platform documents, applications and users'
						icon: Icon
							icon: search
							size: 18
						content: ({close}) ->
							SearchPanel {}
				]
				secondaryItems: _.compact [
					do =>
						match = matchPath window.location.pathname,
							path: '/projects/:project'
						slug = if match? then match.params.project
						return null if !slug?
						id: 'project-navigation'
						openAs: 'drawer-narrow'
						name: 'Application switcher'
						tooltip: 'Quickly jump between application and recent activities in S360 platform'
						className: styles.ProjectIndicator
						trigger:
							ProjectIndicator {
								slug
								className: styles.ProjectIndicatorAvatar
							}
						content: ({close}) =>
							ProjectNavigation {
								..._.pick @props, ['history', 'location', 'match']
								project: slug
								projectApplications: @projectApplications(ability)
								onClick: (event) ->
									if !event.shiftKey and !event.ctrlKey
										close()
							}
				]
				accessoryItems: [
						id: 'notifications'
						openAs: 'drawer-wide'
						name: 'Search'
						tooltip: 'Search platform documents, applications and users'
						icon: Icon
							icon: bell
							size: 18
						content: ({close}) ->
					,
						id: 'help'
						openAs: 'drawer-wide'
						name: 'Search'
						tooltip: 'Search platform documents, applications and users'
						icon: Icon
							icon: helpCircle
							size: 18
						content: ({close}) ->
					,
						id: 'settings'
						openAs: 'drawer-wide'
						name: 'Search'
						tooltip: 'Search platform documents, applications and users'
						icon: Icon
							icon: settings
							size: 18
						content: ({close}) ->
					,
						id: 'profile'
						openAs: 'drawer-narrow'
						name: 'Profile'
						tooltip: 'Search platform documents, applications and users'
						icon: Icon
							icon: user
							size: 18
						content: ({close}) -> UserPanel {}
					,
				]
			,
				@props.children
