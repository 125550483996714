exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._33MY1ncqmc1ejLSTu7vX2_ {\n  font-size: 1.45rem;\n  font-weight: 600;\n  display: flex;\n  align-items: center;\n}\n._33MY1ncqmc1ejLSTu7vX2_ ._2Oa0SsFrDhOuRmSGcs2hWm {\n  margin-right: 4px;\n}\n._14xaWAz0Lyjle8PkHwNLCT {\n  padding: 0 20px;\n  height: 100%;\n}\n.IwOAbHFtGc8GOx_NYgq14 {\n  display: flex;\n  align-items: center;\n  color: #8993a4;\n  margin-top: 5px;\n}\n.IwOAbHFtGc8GOx_NYgq14 ._3lTj8J0DmTFsm9x7v9nAvp {\n  font-size: 0.82rem;\n  margin-left: 4px;\n}\n", ""]);
// Exports
exports.locals = {
	"modalHeader": "_33MY1ncqmc1ejLSTu7vX2_",
	"headerIcon": "_2Oa0SsFrDhOuRmSGcs2hWm",
	"modalContent": "_14xaWAz0Lyjle8PkHwNLCT",
	"modalNote": "IwOAbHFtGc8GOx_NYgq14",
	"text": "_3lTj8J0DmTFsm9x7v9nAvp"
};