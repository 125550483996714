export default
	global:
		module: 'Legal'
		overview: 'Home'
		inbox: 'Inbox'
		registry: 'Documentation'
	overview:
		assigned: 'Assigned to me'
		urgent: 'Urgent'
		toReview: 'To review'
		toScan: 'To scan'
		toSpecify: 'To specify'
	details:
		dates: 'Dates'
		identifiers: 'Identifiers'
		entities: 'Involved entities'
	registration:
		basic: 'Basic info'
		category: 'Category'
		details: 'Details'
		review: 'Review'
	props:
		type: 'Type'
		category: 'Category'
		form: 'Type'
		topic: 'Subject'
		receivalDate: 'Received on'
		source: 'Contractor'
		spv: 'Company'
		company: 'Company'
		status: 'State'
		assignee: 'Owner'
		hasScan: 'Scan'
		location: 'Location'
		building: 'Building'
		buildings: 'Buildings'
		phases: 'Phases'
		mainContract: 'Main contract'
		modifiedAt: 'Modified on'
		modifiedBy: 'Modified by'
		createdAt: 'Registered on'
		createdBy: 'Registered by'
		age: 'Age'
		creationDate: 'Document date'
		approvedAt: 'Approved on'
		entities: 'Involved entities'
		approvedBy: 'Approved by'
		validationDate: 'Validity date'
		expirationDate: 'Expiration date'
		originalDocument: 'Duplicated from'
		number: 'Number'
		caseNumber: 'Case number'
		landRegisterNumber: 'Land register number'
		NIPNumber: 'NIP number'
		KRSNumber: 'KRS number'
		VatNumber: 'Vat number'
		REGONNumber: 'REGON number'
		parcelNumber: 'Parcel number'
		precinctNumber: 'Precinct number'
		ExternalID: 'External ID'
		recipient: 'Recipient'
		participants: 'Participants'
		notes: 'Notes'
		path: 'Path'
	statuses:
		Categorized: 'To specify'
		Filled: 'To review'
		Filled_noScan: 'To scan'
		Reviewed: 'To approve'
		Approved: 'Approved'

	tooltips:
		fresh: 'Document freshly added, modified, shared or assigned to you'
		urgent: 'Urgent document requiring action for less than 30 days'
		muted: 'Urgent document which alerts were muted'
		transfered: 'Scan transferred from Conject - requires registration'
	error:
		invalidDocument: 'This document does not exist'
		invalidProject: 'This project does not exist'
		noPermissionForDocument:
			header: 'You have no access to see this document'
			description: 'Ask Project Leader to get access'
		deletedDocument:
			header: 'This document was deleted.'
			description: 'You need to contact System Administrator to recover it.'
		actionFailure: 'Action failed'
