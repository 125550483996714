# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'
import adopt from 'libs/adopt'
import {FormatterConsumer} from 'libs/formatter'
import { NotificationConsumer } from '../../../application/components/NotificationManager'
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
import { div, p, ul, li, small, h1, h2, h3, span, img, button } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { MasterDetailContentWrapper as _MasterDetailContentWrapper} from '@bevy/master-detail'
MasterDetailContentWrapper = React.createFactory _MasterDetailContentWrapper

import {ContentDetails as _ContentDetails} from '@bevy/content-controller'
ContentDetails = React.createFactory _ContentDetails

import { Route as _Route } from 'react-router-dom'
Route = React.createFactory _Route

import _RouterTabs from '@bevy/router-tabs'
RouterTabs = React.createFactory _RouterTabs

import _Avatar from '@atlaskit/avatar'
Avatar = React.createFactory _Avatar

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

# Elements
import getKPIs from './elements/kpi'
import getHeader from './elements/header'
import getToolbar from './elements/toolbar'
import getTable from './elements/table'
import getModals from './elements/modals'
import { MEMBERS_COLUMNS } from './elements/columns'

# Style
import styles from './index.styl'
import {
} from '@bevy/theme/icons'
import {
	plus
	minus
	alertTriangle
} from 'react-icons-kit/feather'

# Data
import {
	GroupQuery
} from './data'

ITEMS_PER_PAGE = 100

DataLayer = adopt {
	queries: {
		detailsQuery: ({id, sortKey, sortOrder }) ->
			query: GroupQuery
			partialRefetch: true
			variables:
				id: id
				pagination:
					limit: ITEMS_PER_PAGE
				sort:
					key: sortKey
					order: if sortOrder is 'asc' then 'Ascending' else 'Descending'
			onCompleted: (data) ->
				console.log data
	}
	mutations: {
	}
}

export default class GroupDetails extends React.Component
	constructor: (props) ->
		super props
		@state =
			activeModal: null
			selected: []
			documentsTableColumns: @getInitialColumnsConfiguration()
			activeSearch: null
			sortKey: 'Name'
			sortOrder: 'asc'

	getKPIs: getKPIs
	getHeader: getHeader
	getToolbar: getToolbar
	getTable: getTable
	getModals: getModals

	getInitialColumnsConfiguration: () ->
		savedConfig = JSON.parse localStorage.getItem('tableView-groups')
		if savedConfig?
			_.map savedConfig, (column) ->
				columnTemplate = _.find MEMBERS_COLUMNS, {key: column.key}
				{
					...columnTemplate
					selected: column.selected
				}
		else MEMBERS_COLUMNS

	changeTableConfiguration: (newTableConfig) =>
		if !newTableConfig? #restore default
			localStorage.removeItem 'tableView-groups'
			@setState documentsTableColumns: MEMBERS_COLUMNS
		else
			localStorage.setItem('tableView-groups', JSON.stringify newTableConfig)
			@setState documentsTableColumns: newTableConfig

	handleItemsSelect: (ids) =>
		@setState
			selected: _.concat [], ids

	handleSortChange: (key, order) =>
		@setState
			sortKey: key
			sortOrder: order
			fetchedAll: false

	render: ->
		selected = @state.selected
		NotificationConsumer {}, ({ setNotification }) =>
			UserInfoConsumer {}, ({ ability, me, logAs }) =>
				Route
					path: @props.route
					exact: false
					render: ({match, location, history}) =>
						DataLayer {
							setNotification
							id: @props.id
							sortKey: @state.sortKey
							sortOrder: @state.sortOrder
						}, (data) =>
							{detailsQuery} = data
							members = if detailsQuery.data?.userGroup? then _.map detailsQuery.data.userGroup.members, 'user' else []
							ContentDetails
								header: @getHeader {data}
								kpi: @getKPIs {data}
								toolbar: @getToolbar {data, selected}
							,
								MasterDetailContentWrapper {className: styles.wrapper},
									Fragment {},
										@getModals {data, selected}
										@getTable {
											items: members
											loading: detailsQuery.loading
											fetchMore: detailsQuery.fetchMore
											itemsCount:  if !detailsQuery.loading and detailsQuery.data?.userGroup?
												_.size detailsQuery.data.userGroup.members
											columnsSetup: @state.documentsTableColumns
											changeTableConfiguration: @changeTableConfiguration
											selected
											onSelect: @handleItemsSelect
											onSortChange: @handleSortChange
											sortKey: @state.sortKey
											sortOrder: @state.sortOrder
											# getItemActions: (document) =>
											# 	{secondary} = @getToolbar {userGroup, selected, ability,	me,	projectID}
											# 	secondary
										}
