###
S360 application / platform layout and navigation
###

# Libs
import _ from 'lodash'
import React from 'react'
import gql from 'graphql-tag'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, split as SplitLink, Observable } from 'apollo-link'
import { onError } from 'apollo-link-error'
import S3UploadLink from 'libs/s3-upload-link'
import { HttpLink } from 'apollo-link-http'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { IntrospectionFragmentMatcher, InMemoryCache } from 'apollo-cache-inmemory'
import { modeGenerator } from '@atlaskit/navigation-next'
import { withAdalLoginApi, getUser, getToken, login} from '../wrapper'
import { abilityFromRules } from '@bevy/s360-permissions'
import customFetch from './custom-fetch'
import cnames from 'classnames'
import * as Sentry from '@sentry/browser'
import punycode from 'punycode'
import {
	matchPath
	withRouter
} from 'react-router-dom'
# Renderable
import { div, p } from 'react-dom-factories'

# Data
import { LoggedInUser, PermissionSubscription } from './data'

Fragment = React.createFactory React.Fragment

import { ApolloProvider as _ApolloProvider } from 'react-apollo'
ApolloProvider = React.createFactory _ApolloProvider

import { UserInfoConsumer, UserInfoProvider } from 'libs/userInfo'

import refreshPermissionsLink from 'libs/refreshPermissionsLink'

import _RefetchProvider from 'libs/refetch'
RefetchProvider = React.createFactory _RefetchProvider

import { ThemeProvider as _ThemeProvider } from 'emotion-theming'
ThemeProvider = React.createFactory _ThemeProvider

import {
	NavigationProvider as _NavigationProvider
	LayoutManager as _LayoutManager
	Section as _Section
} from '@atlaskit/navigation-next'
NavigationProvider = React.createFactory _NavigationProvider
LayoutManager = React.createFactory _LayoutManager
Section = React.createFactory _Section

import _Spinner from '@atlaskit/spinner'
Spinner = React.createFactory _Spinner

import {
	BrowserRouter as _BrowserRouter
	Route as _Route
	Switch as _Switch
	Link as _Link
	Redirect as _Redirect
} from 'react-router-dom'
BrowserRouter = React.createFactory _BrowserRouter
Route = React.createFactory _Route
Switch = React.createFactory _Switch
Link = React.createFactory _Link
Redirect = React.createFactory _Redirect

import _LoginScreen from './components/LoginScreen'
LoginScreen = React.createFactory _LoginScreen

import _NotificationManager from './components/NotificationManager'
NotificationManager = React.createFactory _NotificationManager

import _GlobalNavigation from './components/GlobalNavigation'
GlobalNavigation = React.createFactory _GlobalNavigation

import _LoggedAsBanner from './components/LoggedAsBanner'
LoggedAsBanner = React.createFactory _LoggedAsBanner

import _PlatformSpinner from './components/PlatformSpinner'
PlatformSpinner = React.createFactory _PlatformSpinner

import HTML5Backend from 'react-dnd-html5-backend'
import { DndProvider as _DndProvider } from 'react-dnd'
DndProvider = React.createFactory _DndProvider

# import _DocumentDetails from 'parts/DocumentDetails'
# DocumentDetails = React.createFactory _DocumentDetails

import {
	SpotlightManager as _SpotlightManager
} from '@atlaskit/onboarding'

import { PlatformAcess } from 'components/ErrorDisplay'

SpotlightManager = React.createFactory _SpotlightManager

import MainRouter from './routing'

# Styles
import styles from './index.styl'
import {
	home
	briefcase
	alignJustify
	fileText
} from 'react-icons-kit/feather'

# stage = if __STAGE__? then __STAGE__ else 'local'
# resolverStack = require "../../../../resolver/#{stage}.json"
serviceEnpoints =
	local: 'http://localhost:8001/dev'
	test: 'https://cyoryftm30.execute-api.eu-central-1.amazonaws.com/test'
	development: 'https://jktixcgh8k.execute-api.eu-central-1.amazonaws.com/development'
	staging: 'https://u8c0fne9i2.execute-api.eu-central-1.amazonaws.com/staging'
	production: 'https://8op5v0e8q3.execute-api.eu-central-1.amazonaws.com/production'
wsEndpoints =
	local: 'ws://localhost:3001'
	test: 'wss://6hnsqooajj.execute-api.eu-central-1.amazonaws.com/test/'
	development: 'wss://fsl6srgjnj.execute-api.eu-central-1.amazonaws.com/development/'
	staging: 'wss://cslh9ixn14.execute-api.eu-central-1.amazonaws.com/staging/'
	production: 'wss://015s78n0f9.execute-api.eu-central-1.amazonaws.com/production/'
RESOLVER_URI = serviceEnpoints[__STAGE__ || 'local']
WEBSOCKET_URI = wsEndpoints[__STAGE__ || 'local']

# Navigation cache override
LS_KEY = 'ATLASKIT_NAVIGATION_UI_STATE'
FAKE_USER_KEY = 'LOGGED_AS_USER'
CONTENT_NAV_WIDTH = 270

UiCacheOverride =
	get: ->
		stored = localStorage.getItem LS_KEY
		if stored?
			saved = JSON.parse stored
			saved.isPeekHinting = false
			saved.isPeeking = false
			saved
		else
			isPeekHinting: false
			isPeeking: false
			isCollapsed: false
			productNavWidth: CONTENT_NAV_WIDTH
			isResizing: false
	set: (state) ->
		localStorage.setItem LS_KEY, JSON.stringify state


export default withRouter class S360Application extends React.Component
	constructor: (props) ->
		super props

		@formatterMoney = new Intl.NumberFormat 'pl-PL',
			style: 'decimal'
			minimumFractionDigits: 2
			maximumFractionDigits: 2
		@formatterInteger = new Intl.NumberFormat 'pl-PL',
			style: 'decimal'
			minimumFractionDigits: 0
			maximumFractionDigits: 0
		@formatterEur = new Intl.NumberFormat 'pl-PL',
			style: 'currency'
			currency: 'EUR'
			minimumFractionDigits: 2
			maximumFractionDigits: 2
			currencyDisplay: 'symbol'
		@formatterPln = new Intl.NumberFormat 'pl-PL',
			style: 'currency'
			currency: 'PLN'
			minimumFractionDigits: 2
			maximumFractionDigits: 2
			currencyDisplay: 'symbol'
		@formatterSqm = new Intl.NumberFormat 'pl-PL',
				style: 'decimal'
				minimumFractionDigits: 2
				maximumFractionDigits: 2
		@formatterUnits = new Intl.NumberFormat 'pl-PL',
				style: 'decimal'
				minimumFractionDigits: 0
				maximumFractionDigits: 0
		fakeUser = localStorage.getItem FAKE_USER_KEY
		if fakeUser?
			fakeUser = JSON.parse fakeUser
		@state =
			index: 0
			dbInitialised: false
			user: null
			loader: true
			fakeUser: fakeUser
			drawers:
				appSwitcher: false
				search: false
	navigate: (path) =>
		(event) =>
			if event? and !event.ctrlKey and !event.metaKey
				event.preventDefault()
				@props.history.push path
			else if !event?
				@props.history.push path
	closeDrawer: (drawer) =>
		=>
			@setState drawers: {
				...@state.drawers
				"#{drawer}": false
			}
	matchPath: (path, exact = true) ->
		match = matchPath window.location.pathname,
			path: path
			exact: exact
		match?

	componentDidMount: ->
		setUpClient = (token, user) =>
			linkSettings =
				uri: RESOLVER_URI
				headers:
					if token?
						Authorization: "Bearer #{token}"
					else
						{}
				fetch: customFetch
			if @state.fakeUser then linkSettings.headers['X-User-Perspective'] = @state.fakeUser.id
			@client = new ApolloClient
				link: ApolloLink.from [
					onError ({ graphQLErrors, networkError, response, operation }) =>
						if networkError?.statusCode? and networkError.statusCode in [401, 440]
							@login()
					new S3UploadLink (file) =>
						new Promise (resolve, reject) =>
							# Register new tmp file
							@client.mutate
								variables:
									metadata:
										filename: punycode.encode file.name
								mutation: gql '''
									mutation requestFile($metadata: JSONObject) {
										requestTemporaryFileStorage(metadata: $metadata) {
											id
											url
										}
									}
								'''
							.catch reject
							.then (result) ->
								if !result?
									return reject new Error 'Cannot fetch temporary file storage ticket'
								{data: requestTemporaryFileStorage: {url, id}} = result
								fetch url, {
									method: 'PUT'
									headers:
										'Content-Type': file.type
									body: file
								}
								.catch reject
								.then (res) ->
									resolve id
					new refreshPermissionsLink (refreshID) =>
						new Promise (resolve, reject) =>
							@client.query(query: LoggedInUser, fetchPolicy: 'network-only')
							.catch (err) =>
								if @state.fakeUser?
									localStorage.removeItem FAKE_USER_KEY
									window.location.reload()
								else
									console.warn 'User query failed', err
								reject err
							.then ({data}) =>
								@setState
									user: data.me
									ability: abilityFromRules data.me.permissions, true
								, resolve
					SplitLink(
						({query}) ->
							definition = getMainDefinition query
							return definition.kind is 'OperationDefinition' and definition.operation is 'subscription'
						,
							new WebSocketLink
								uri: WEBSOCKET_URI
								options:
									disableProtocol: true
									reconnect: true
									lazy: __STAGE__ isnt 'local'
									connectionParams: {
										...(
											if token? then Authorization: "Bearer #{token}"
										)
										...(
											if @state.fakeUser then 'X-User-Perspective': @state.fakeUser.id
										)
									}
						,
							if __STAGE__ is 'local'
								new HttpLink linkSettings
							else
								new BatchHttpLink {
									...linkSettings
								}
					)
				]
				cache: new InMemoryCache fragmentMatcher: new IntrospectionFragmentMatcher introspectionQueryResultData: require './fragmentsMatcher.json'
				fetch: do ->
					if !document?
						require 'cross-fetch'

			Promise.all [
				new Promise (resolve, reject) =>
					@client.query(query: LoggedInUser)
					.catch (err) =>
						if @state.fakeUser?
							localStorage.removeItem FAKE_USER_KEY
							window.location.reload()
						else
							console.warn 'User query failed', err
						reject err
					.then ({data}) =>
						Sentry.configureScope (scope) ->
							scope.setUser {
								id: data.me.id
								username: data.me.name
							}

						user = data.me
						@setState
							user: user
							ability: abilityFromRules user.permissions, true

						resolve()
			,
				new Promise (resolve, reject) ->
					setTimeout resolve, 1000
			]
			.then =>
				@client.subscribe {query: PermissionSubscription}
				.subscribe
					next: (data) =>
						@client.query(query: LoggedInUser, fetchPolicy: 'network-only')
						.catch (err) =>
							if @state.fakeUser?
								localStorage.removeItem FAKE_USER_KEY
								window.location.reload()
							else
								console.warn 'User query failed', err
							reject err
						.then ({data}) =>
							@setState
								ability: abilityFromRules data.me.permissions, true
				@setState loader: false

		if __STAGE__? and (__STAGE__ isnt 'local')
			getUser (err, user) =>
				if err?
					@setState user: false
				else
					getToken (err, token) =>
						if !token? # Session expired
							@setState user: false
						else
							setUpClient token, user
		else
			setUpClient()

	login: =>
		login()
		if @redirect?
			localStorage.setItem('adal.login.request', @redirect)

	render: ->
		DndProvider {backend: HTML5Backend},
			Switch {},
				Route
					exact: true
					path: '/login'
					render: (props) =>
						if @state.user? and @state.user isnt false
							Redirect to: '/'
						else
							LoginScreen {onLogin: @login}
				# TODO refactor
				# Route
				# 	path: '/legalDocuments/:document'
				# 	render: (props) =>
				# 		if !@state.user?
				# 			return Spinner {}
				# 		else if @state.user is false
				# 			return Redirect to: '/login'
				# 		ApolloProvider {client: @client},
				# 			UserInfoProvider
				# 				value:
				# 					ability: @state.ability
				# 					me: @state.user
				# 					logAs: (user) =>
				# 						localStorage.setItem FAKE_USER_KEY, JSON.stringify _.pick user, ['id', 'name']
				# 						window.location.reload()
				# 			,
				# 				NotificationManager {},
				# 					RefetchProvider {},
				# 						DocumentDetails {
				# 							mobile: true
				# 							...props
				# 						}

				Route
					render: (props) =>
						match = matchPath window.location.pathname,
							path: '/projects/:project'
						hasContainerNav = match?
						isBuildingEditor = !_.isEmpty matchPath(window.location.pathname,
							path: '/projects/:project/leasing/editor'
						)
						customThemeMode = modeGenerator
							product:
								text: '#FFF'
								background: '#0747A6'
						pageProps =
							format:
								eur: @formatterEur.format
								pln: @formatterPln.format
								money: @formatterMoney.format
								integer: @formatterInteger.format
								sqm: (val) => "#{@formatterSqm.format(val)} sqm."
								units: (val) => "#{@formatterUnits.format(val)} units"
							navigate: @navigate
							className: cnames [styles.page, if @state.fakeUser? then styles.withBanner]
						@redirect = window.location.href
						# if !@state.user?
						# 	return PlatformSpinner {}
						if @state.user is false
							return Redirect to: '/login'
						Fragment {},
							PlatformSpinner {visible: @state.loader}
							if @state.user? and @state.user isnt false
								if __STAGE__ isnt 'local' and @state.ability.cannot 'read', 'Platform', __STAGE__
									PlatformAcess {ability: @state.ability}
								else
									content = React.createElement MainRouter, {...pageProps}
									ApolloProvider {client: @client},
										UserInfoProvider
											value:
												ability: @state.ability
												me: @state.user
												logAs: (user) ->
													localStorage.setItem FAKE_USER_KEY, JSON.stringify _.pick user, ['id', 'name']
													window.location.reload()
										,
											NavigationProvider {cache: UiCacheOverride},
												NotificationManager {},
													RefetchProvider {},
														ThemeProvider {
															theme: (theme) ->
																{
																	...theme
																	mode: customThemeMode
																}
														}, do =>
															if isBuildingEditor
																content
															else
																Fragment {},
																	SpotlightManager {blanketIsTinted: true},
																		GlobalNavigation props,
																				# LoggedAsBanner
																				# 	isOpen: true
																				# 	warning: 'Due to infrastructure modifications and migration of the DMS application, minor functional incidents may occur on August 12-15. We apologize for the inconvenience - we are improving for you!'
																				LoggedAsBanner
																					isOpen: @state.fakeUser?
																					userName: if @state.fakeUser?.name? then @state.fakeUser.name else '?'
																					onDone: =>
																						localStorage.removeItem FAKE_USER_KEY
																						window.location.reload()
																				content
