import _ from 'lodash'

export default ({data}) ->
	if _.isEmpty data.detailsQuery.data then {}
	else
		{userGroup} = data.detailsQuery.data
		title: userGroup.name
		description: userGroup.description
		owner:
			src: userGroup.picture.mini
