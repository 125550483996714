import _ from 'lodash'
import React from 'react'

import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import {
	add
} from '@bevy/theme/icons'
import styles from '../index.styl'

export default ({members, selected, ability, me, logAs, projectID}) ->
	selectedMembers = _.filter members, (user) -> _.some selected, (selectedID) -> selectedID is user.id
	selectedMembers = _.map selectedMembers, (user) -> {...user, projectID: projectID}
	primary =
		# if ability.can 'create', 'Personnel'
			key: 'add'
			content: 'New'
			iconBefore: Icon add
			isDisabled: !ability.can 'create', 'User'
			onClick: => @setState activeModal: 'add'
			'data-test-id': 'toolbar-button-add-new-user'
	secondary = [
		# if ((_.size selected) is 1)
		if ((_.size selected) is 1) and (selectedMembers[0]?) and (ability.can 'update', selectedMembers[0].user)
			key: 'edit'
			content: 'Edit'
			onClick: => @setState activeModal: 'edit'
			'data-test-id': 'toolbar-button-edit-user'
	,
		# if ((_.size selected) is 1)
		if  ((_.size selected) is 1) and _.every selectedMembers, (member) -> ability.can 'delete', member.user
		# if (!_.isEmpty selected)
			key: 'delete'
			content: 'Delete'
			onClick: => @setState activeModal: 'delete'
			'data-test-id': 'toolbar-button-delete-user'
	,
		# if ((_.size selected) is 1)
		if ((_.size selected) is 1) and ((_.first selected).id isnt me.id) and (ability.can 'logAs', 'User')
			key: 'logAs'
			content: 'Login as'
			onClick: =>
				member = _.first selectedMembers
				logAs {id: member.id, name: member.user.name}
			'data-test-id': 'toolbar-button-logAs-user'
	]
	primary: primary
	secondary: _.compact secondary

