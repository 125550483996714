import _ from 'lodash'
import printJS from 'print-js'
import moment from 'moment-mini'
import filesize from 'filesize'

stage = if __STAGE__? then __STAGE__ else 'local'

export DOWNLOAD_TIMEOUT = 6000

export getConjectPath = (path, short = false) ->
	fullPath = []
	if path?
		_.map path.ancestors, (ancestor) ->
			ancestorCode = if ancestor.code < 10 then "0#{ancestor.code}" else ancestor.code
			if short then fullPath.push ancestorCode else fullPath.push("#{ancestorCode} #{ancestor.name}")
		pathCode = if path.code < 10 then "0#{path.code}" else path.code
		if short then fullPath.push pathCode else fullPath.push("#{pathCode} #{path.name}")
		_.toUpper _.join fullPath, ' / '
	else
		'-'

export convertToOptions = (items) ->
	if items?
		if _.isArray items
			items = _.map items, (item) ->
				label:
					if item.name? then item.name
					else if item.label? then item.label
					else if item.value? then item.value
					else item
				value:
					if item.key? then item.key
					else if item.code? then item.code
					else if	item.value? then item.value
					else if item.id? then item.id
					else if item.slug? then item.slug
					else item
		else if items.label? and items.value?
			items
		else
			label: items.name
			value: if items.id then items.id else items.name
	else
		[]

export isModified = (item) ->
	(item.isFresh and (item.createdAt > moment().subtract(3, 'minute').unix())) or
	(item.scan?.uploadedAt? and item.scan.uploadedAt > moment().subtract(3, 'minute').unix()) or
	(item.categorizedAt and item.categorizedAt > moment().subtract(3, 'minute').unix()) or
	(item.approvedAt? and item.approvedAt > moment().subtract(3, 'minute').unix()) or
	(item.isUrgent and item.isFresh)

export handleQRPrint = ({format, legalDocument, onQRPrintEnd}) ->
	document =
		id: legalDocument.id
		createdAt: legalDocument.createdAt
		createdBy: legalDocument.createdBy.name
		url: "#{window.location.origin}/legalDocuments/#{legalDocument.id}"
		project: legalDocument.project.name
		company: legalDocument.company.name
		source: legalDocument.correspondent.name
		date: _.find(legalDocument.dates, type: 'ReceivalDate').value
		form: legalDocument.form.name
		topic: legalDocument.topic.name
		fullPath: getConjectPath legalDocument.path
		shortPath: getConjectPath legalDocument.path, true

	#FIXME should use api proxy
	_stage = if stage is 'local' then 'development' else stage
	serviceEndpoints =
		development: 'https://jn2nmd634m.execute-api.eu-central-1.amazonaws.com/development'
		test: 'https://80a54u0kaj.execute-api.eu-central-1.amazonaws.com/test'
		production: 'https://7xk8wri3dl.execute-api.eu-central-1.amazonaws.com/production'

	url = serviceEndpoints[_stage] + "?format=#{format}&document=#{encodeURIComponent JSON.stringify document}"
	fetch url,
		mode: 'no-cors'
	.then (data) =>
		printJS
			printable: "https://s3.eu-central-1.amazonaws.com/s360-dms-qr-tags/#{_stage}/#{legalDocument.id}/#{format}.pdf"
			type: 'pdf'
			onLoadingStart: () -> # leave to display print window
			onLoadingEnd: () => onQRPrintEnd format
			onError: () => onQRPrintEnd format

export stringifyLoadedPDFsNames = (pdfs) ->
	MAX_FILES_VISIBLE = 4
	MAX_STRING_LENGTH = 15
	string = []
	_.map (_.take pdfs, MAX_FILES_VISIBLE), (file, index) =>
		fileName =
			if (_.size file.name) > MAX_STRING_LENGTH
				file.name.substring(0, MAX_STRING_LENGTH)
			else
				file.name
		fileSize = filesize file.size
		suffix = if pdfs[index+1]? then ', ' else ''
		string.push "#{fileName} (#{fileSize})#{suffix}"
	if (_.size pdfs) > MAX_FILES_VISIBLE
		string.push '...'
	_.join string, ''

export getRefetchQueries = (queries, docState) ->
	if (docState is 'Draft') or (docState is 'inbox')
		_.concat queries, 'DMS_Inbox_Table'
	else if (docState is 'Commited') or (docState is 'registry')
		_.concat queries, 'DMS_Registry_Table'
	else #if nothing provided then refetch bouth lists
		_.concat queries, ['DMS_Inbox_Table',  'DMS_Registry_Table']

