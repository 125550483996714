"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pMapSeries = exports.pseries = exports.visitAssociationCondition = exports.projectAssociationCondition = exports.identityCondition = exports.isSelfDeletedCondition = exports.makeDefaultSubject = exports.makeDefaultFields = exports.makeSetOfAccepts = exports.getLocalID = exports.makeLocalID = exports.makeGlobalID = exports.unbase64 = exports.base64 = void 0;

var _core = _interopRequireDefault(require("lodash/core"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Generated by CoffeeScript 2.4.1
var makeLocalId;

var base64 = function (i) {
  return new Buffer(i, 'utf8').toString('base64');
};

exports.base64 = base64;

var unbase64 = function (i) {
  return new Buffer(i, 'base64').toString('utf8');
};

exports.unbase64 = unbase64;

var makeGlobalID = function (name, id) {
  return base64(`${name}:${id}`);
};

exports.makeGlobalID = makeGlobalID;

var makeLocalID = makeLocalId = function (id) {
  var name;
  [name, id] = unbase64(id).split(':');
  return {
    name,
    id
  };
};

exports.makeLocalID = makeLocalID;

var getLocalID = function (globalId) {
  return makeLocalId(globalId).id;
};

exports.getLocalID = getLocalID;

var makeSetOfAccepts = function (accepts, roles) {
  if (_core.default.isString(accepts)) {
    accepts = [accepts];
  }

  return _core.default.map(roles, function (role) {
    return _objectSpread({}, role, {
      accepts
    });
  });
};

exports.makeSetOfAccepts = makeSetOfAccepts;

var makeDefaultFields = function (fields, actions = {}) {
  return _core.default.reduce(actions, function (acc, body, key) {
    acc[key] = _objectSpread({
      fields
    }, body);
    return acc;
  }, {});
};

exports.makeDefaultFields = makeDefaultFields;

var makeDefaultSubject = function (subject, scopes) {
  return _core.default.reduce(scopes, function (acc, val, key) {
    if (_core.default.isArray(val)) {
      acc[key] = _core.default.map(val, function (rule) {
        return _objectSpread({
          subject
        }, rule);
      });
    } else if (val.actions != null) {
      acc[key] = _objectSpread({
        subject
      }, val);
    } else {
      acc[key] = makeDefaultSubject(subject, val);
    }

    return acc;
  }, {});
};

exports.makeDefaultSubject = makeDefaultSubject;
var isSelfDeletedCondition = {
  $or: [{
    isDeleted: false
  }, {
    isDeleted: {
      $exists: false
    }
  }]
};
exports.isSelfDeletedCondition = isSelfDeletedCondition;

var identityCondition = function (resolved, typeOfEntity, entity) {
  var id;
  id = _core.default.isObject(entity) ? entity._id : entity;

  if (resolved) {
    return {
      id: makeGlobalID(typeOfEntity, id)
    };
  } else {
    return {
      _id: id
    };
  }
};

exports.identityCondition = identityCondition;

var projectAssociationCondition = function (resolved, project) {
  if (resolved) {
    return {
      $or: [{
        projectID: makeGlobalID('project', project._id)
      }, {
        'project.id': makeGlobalID('project', project._id)
      }]
    };
  } else {
    return {
      projectID: project._id
    };
  }
};

exports.projectAssociationCondition = projectAssociationCondition;

var visitAssociationCondition = function (resolved, visit) {
  if (resolved) {
    return {
      visitID: makeGlobalID('visit', visit._id)
    };
  } else {
    return {
      visitID: visit._id
    };
  }
};

exports.visitAssociationCondition = visitAssociationCondition;

var pseries = function (list) {
  var p;
  p = Promise.resolve();
  return list.reduce(function (pacc, fn) {
    return pacc = pacc.then(fn);
  }, p);
};

exports.pseries = pseries;

var pMapSeries = async function (iterable, mapper) {
  var index, j, len, result, value;
  result = [];
  index = 0;

  for (j = 0, len = iterable.length; j < len; j++) {
    value = iterable[j];
    result.push((await mapper((await value), index++)));
  }

  return result;
};

exports.pMapSeries = pMapSeries;