import _ from 'lodash'
import React from 'react'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import {BevyModalsWrapper as _BevyModalsWrapper} from '@bevy/modal'
BevyModalsWrapper = React.createFactory _BevyModalsWrapper

import _AddCompanyModal from 'parts/Companies/AddCompanyModal'
AddCompanyModal = React.createFactory _AddCompanyModal

import _EditCompanyModal from 'parts/Companies/EditCompanyModal'
EditCompanyModal = React.createFactory _EditCompanyModal

import _DeleteCompanyModal from 'parts/Companies/DeleteCompanyModal'
DeleteCompanyModal = React.createFactory _DeleteCompanyModal

import { NotificationConsumer } from '../../../application/components/NotificationManager'

selectedOrganizations = null

export default getModals = ({selected, projectID}) ->
	if projectID?
		NotificationConsumer {}, ({setNotification}) =>
			Fragment {},
				BevyModalsWrapper {},
					switch @state.activeModal
						when 'add'
							AddCompanyModal
									projectID: projectID
									onClose: => @setState activeModal: null
						when 'edit'
							EditCompanyModal
								organizationID: @state.selected[0]
								projectID: projectID
								onClose: => @setState activeModal: null
						when 'delete'
							DeleteCompanyModal
								organizationsIDs: @state.selected
								projectID: projectID
								onClose: =>
									selectedOrganizations = @state.selected
									@setState
										activeModal: null
										selected: []
								onConfirm: ({organizationsDelete}) ->
									if _.isEmpty organizationsDelete
										setNotification({content: 'Cannot remove organizations in use', appearance: 'error'})
									else if _.size(organizationsDelete) isnt _.size selectedOrganizations
										setNotification({content: 'Some organization cannot be removed', appearance: 'warning'})
									else
										setNotification({content: 'Organizations deleted', appearance: 'success'})
