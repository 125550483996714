###
Add Company Modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import adopt from 'libs/adopt'
import { convertToOptions } from 'libs/legal'

import { NotificationConsumer } from '../../../application/components/NotificationManager'
import { UserInfoConsumer } from 'libs/userInfo'

# Renderable
import { div, span } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _BevyModal from '@bevy/modal'
BevyModal = React.createFactory _BevyModal

import { Field as _Field } from '@atlaskit/form'
Field = React.createFactory _Field

import _Select, {LoadableSelect as _LoadableSelect} from '@bevy/select'
Select = React.createFactory _Select
LoadableSelect = React.createFactory _LoadableSelect

import _FieldText from '@atlaskit/textfield'
FieldText = React.createFactory _FieldText

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

# Styles
import styles from './index.styl'
import { info } from 'react-icons-kit/feather'

# Data
import {
	GetRoles
	GetNotMembers
	UpdateUser
} from './data'

REFETCH_QUERIES = ['ProjectUsersList', 'GetNotMembers']

DataLayer = adopt
	queries:
		getRoles: ->
			query: GetRoles
	mutations:
		updateUser: ({setNotification}) ->
			mutation: UpdateUser
			onCompleted: -> setNotification({content: 'User successfully added to the project', appearance: 'success'})

emptyState = {
	userID: null
	roles: []
}

commonSelectProps =
	menuPortalTarget: document.body
	isSearchable: true
	hideSelectedOptions: true
	isClearable: false
	formatOptionLabel: (option) -> option.label
	getOptionLabel: (option) -> option.label
	getOptionValue: (option) -> option.value

export default class AddUserModal extends React.Component
	@propTypes =
		onClose: PropTypes.func.isRequired

	constructor: (props) ->
		super props
		@state = {
			...emptyState
			}

	makeUserOptions: (arr) ->
		if !_.isEmpty arr
			_.map arr, (item) ->
				label: "#{item.name} (#{item.email})"
				value: item.id
		else
			[]

	canProceed: =>
			if (!_.isEmpty @state.userID) and (!_.isEmpty @state.roles)
				true
			else
				false

	getVariables4Mutation: =>
		userID: @state.userID
		addedProjects: _.map @state.roles, (role) =>
			{
				id: @props.projectID
				role: role
			}

	onCloseProxy: (appropriateMutation) =>
		if _.isFunction appropriateMutation
			appropriateMutation
				variables: @getVariables4Mutation()
				refetchQueries: REFETCH_QUERIES

		@setState {
			...emptyState
			}
		@props.onClose()

	renderLabel: ({label, tooltipContent, isRequired}, children) =>
		Field
			label: span {className: styles.label},
				label
				if tooltipContent?
					div {className: cnames styles.iconTooltip, if isRequired then styles.moreMargin},
						Tooltip {content: tooltipContent}, Icon {icon: info, size: 9}
			name: label
			isRequired: isRequired
		, =>
			children

	renderTextField: ({label, slug, isRequired, tooltipContent, maxLength}) =>
		@renderLabel {label, tooltipContent, isRequired},
			div {className: styles.formItemText},
				FieldText
					className: styles.formItem
					isLabelHidden: true
					onChange: (e) => @setState ["#{slug}"]: e.target.value
					value: @state["#{slug}"]
					shouldFitContainer: true
					maxLength: maxLength

	renderSelect: ({label, slug, isRequired, tooltipContent, options, isDisabled, isMulti}) =>
		@renderLabel {label, tooltipContent, isRequired},
			Select {
				...commonSelectProps
				isMulti
				isDisabled
				options
				resetMultiFormatting: true
				onChange: (item) =>
					if isMulti
						@setState ["#{slug}"]: _.map item, (opt) -> opt.value
					else
						@setState ["#{slug}"]: item.value
				value: _.find options, (item) => item.value is @state["#{slug}"]
			}

	render: ->
		UserInfoConsumer {}, ({ability, me}) =>
			NotificationConsumer {}, ({setNotification}) =>
				DataLayer
					setNotification: setNotification
					projectID: @props.projectID
					, (operations) =>
						BevyModal
							onClose: @onCloseProxy
							header: 'Add user'
							actions: [
								text: 'Save'
								isDisabled: !@canProceed()
								onClick: () => @onCloseProxy operations.updateUser.mutation
							,
								text: 'Cancel'
								onClick: @onCloseProxy
							]
						,
							if operations.getRoles?.data?
								Fragment {},
									@renderLabel {
										label: 'Name'
										tooltipContent: 'To add new user please contact administrator.'
									},
										LoadableSelect {
											...commonSelectProps
											query:
												query: GetNotMembers
												variables:
													projectID: @props.projectID
													pagination:
														limit: 20
											makeOptionsFromData: (data) =>
												if data?.projectByID?
													@makeUserOptions data.projectByID.notMembers
												else
													[]
											handleValueToDisplay: (currentValue, listOfPossibilities) =>
												if currentValue? and listOfPossibilities?
													itemToDisplay = _.find listOfPossibilities, (item) -> item.value is currentValue
													if itemToDisplay?
														label: itemToDisplay.label
														value: itemToDisplay.value
											values: [@state.userID]
											onChange: (item) => @setState userID: item.value
											hasMore: @state.hasMore
											handleDataUpdate: (prev, fetchMoreResult) =>
												if _.isEmpty fetchMoreResult.projectByID.notMembers
													@setState hasMore: false
												{
													...prev
													projectByID: {
														...prev.projectByID
														notMembers: [...prev.projectByID.notMembers, ...fetchMoreResult.projectByID.notMembers]
													}
												}
										}
									@renderSelect
										label: 'Project roles'
										slug: 'roles'
										hideSelectedOptions: false
										options: do ->
											if _.get(operations.getRoles, 'data.permissionsModel.membersRoles')?
												possibleRoles = _.sortBy operations.getRoles.data.permissionsModel.membersRoles, 'name'
												possibleRoles = _.filter possibleRoles, (role) -> 'User' in role.models
												convertToOptions _.map possibleRoles, 'name'
										isMulti: true
										tooltipContent: 'To add new user project role please contact administrator.'
										isDisabled: _.isEmpty @state.userID




