"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.scopes = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _helpers = require("../helpers");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Generated by CoffeeScript 2.4.1
var LegalDocumentScopes;

/*
LegalDocument permissions model
*/
var scopes = (0, _helpers.makeDefaultSubject)('LegalDocument', {
  Manage: {
    actions: 'crud'
  },
  Create: {
    actions: 'create'
  },
  Read: {
    actions: 'read'
  },
  ReadDraft: {
    actions: 'read',
    conditions: {
      state: 'Draft'
    }
  },
  ReadCommited: {
    actions: 'read',
    conditions: {
      state: 'Commited'
    }
  },
  UpdateCommited: {
    actions: 'update',
    conditions: {
      state: 'Commited'
    }
  },
  Update: {
    actions: 'update'
  },
  UpdateDetails: {
    actions: 'update',
    fields: 'details'
  },
  UpdateScan: {
    actions: 'update',
    fields: 'scan'
  },
  UpdateAssignee: {
    actions: 'update',
    fields: 'assignee'
  },
  UpdateACL: {
    actions: 'update',
    fields: 'acl'
  },
  UpdateReview: {
    actions: 'update',
    fields: 'review'
  },
  UpdateState: {
    actions: 'update',
    fields: 'state'
  },
  Delete: {
    actions: 'delete'
  },
  DeleteDraft: {
    actions: 'delete',
    conditions: {
      state: 'Draft'
    }
  },
  DeleteCommited: {
    actions: 'delete',
    conditions: {
      state: 'Commited'
    }
  },
  // read content marked as external
  External: {
    actions: 'external'
  }
});
exports.scopes = scopes;
LegalDocumentScopes = scopes;
var _default = {
  // Name of the GraphQL subject
  name: 'LegalDocument',
  // Database collection
  collection: 'legalDocuments',
  // Name used in making global id's
  globalIDName: 'legalDocuments',
  // Available actions on root leve
  actions: ['crud', 'create', 'read', 'update', 'delete'],
  // Alias for create, read, update, delete
  // Model fields with appropiate actions
  fields: {
    details: {
      description: 'Document details',
      actions: ['update']
    },
    scan: {
      description: 'Attached scan for document',
      actions: ['update']
    },
    assignee: {
      description: 'User assigned as a owner of a document',
      actions: ['update']
    },
    acl: {
      description: 'Share permissions management',
      actions: ['update']
    },
    review: {
      description: 'Review status',
      actions: ['update']
    },
    state: {
      description: 'Current document status e.g. Draft, Commited',
      actions: ['update']
    }
  },
  // Available scopes with implicit subject as a model.name e.g. LegalDocument
  scopes,
  // Additional fields requested when fetching items for attached and members roles
  projection: {},
  // Type of models that can become a member of this model without specified role
  accepts: [],
  // Entity based roles default and assignable
  roles: {
    // Roles granted to all users
    default: [{
      // Legal Document assignee
      name: 'Legal Document assignee',
      description: 'LegalDocument assignee acquires certain rights to update document',
      permissions: function ({
        user,
        parent,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [LegalDocumentScopes.ReadDraft, LegalDocumentScopes.ReadCommited, LegalDocumentScopes.PrintQRCode, LegalDocumentScopes.UpdateACL, LegalDocumentScopes.UpdateScan, LegalDocumentScopes.UpdateDetails, LegalDocumentScopes.UpdateReview, LegalDocumentScopes.UpdateAssignee, LegalDocumentScopes.DeleteDraft],
          conditions: resolved ? {
            'asignee.id': (0, _helpers.makeGlobalID)('user', user._id)
          } : {
            'assigneeData.id': user._id
          }
        }];
      }
    }],
    // Roles assignable to the entity that will be transfered to the User
    attached: [],
    // Roles assignable to the members (Users) of this entity
    members: [// Simple flat membership roles can be defined as default roles
    ...(0, _helpers.makeSetOfAccepts)(['User', 'UserGroup'], [{
      name: 'Legal document reader',
      displayName: 'Read',
      acl: true,
      inline: true,
      permissions: function ({
        user,
        parent: document,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [LegalDocumentScopes.Read],
          conditions: {
            members: {
              $elemMatch: {
                $or: _lodash.default.map(membership, function (obj) {
                  return _objectSpread({}, obj, {
                    role: 'Legal document reader'
                  });
                })
              }
            }
          }
        }];
      }
    }, {
      name: 'Legal document editor',
      displayName: 'Write',
      acl: true,
      inline: true,
      permissions: function ({
        user,
        parent: document,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [LegalDocumentScopes.Read, LegalDocumentScopes.UpdateScan, LegalDocumentScopes.UpdateDetails],
          conditions: {
            members: {
              $elemMatch: {
                $or: _lodash.default.map(membership, function (obj) {
                  return _objectSpread({}, obj, {
                    role: 'Legal document editor'
                  });
                })
              }
            }
          }
        }];
      }
    }])]
  }
};
exports.default = _default;