import _ from 'lodash'
import React from 'react'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import {BevyModalsWrapper as _BevyModalsWrapper} from '@bevy/modal'
BevyModalsWrapper = React.createFactory _BevyModalsWrapper

import _EditCompanyModal from 'parts/Companies/EditCompanyModal'
EditCompanyModal = React.createFactory _EditCompanyModal

import _EditProjectModal from 'parts/Projects/EditProjectModal'
EditProjectModal = React.createFactory _EditProjectModal

import { NotificationConsumer } from '../../../application/components/NotificationManager'

selectedOrganizations = null

export default getModals = ({project}) ->
	if project?
		NotificationConsumer {}, ({setNotification}) =>
			BevyModalsWrapper {},
				switch @state.activeModal
					when 'editCompany'
						EditCompanyModal
							advancedView: true #TODO: change based on permission
							organizationID: if project.spv? then project.spv.id
							projectID: project.id
							onClose: => @setState activeModal: null
					when 'editProject'
						EditProjectModal
							advancedView: true #TODO: change based on permission
							projectID: project.id
							onUpdateComplete: (data) =>
								if @state.originalSlug isnt data.projectUpdate.slug
									@props.history.push "/projects/#{data.projectUpdate.slug}"
							onClose: => @setState activeModal: null
