###
Master detail pattern – work in progrss
TODO: Support not fetching data when query string contains 'full' param (with or without value)
TODO: Support for fetching additional data
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'
import PropTypes from 'prop-types'

# Renderables
import { div, p, ul, li, small, h1, h2, h3, span, img, button } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _InfiniteScroll from 'react-infinite-scroller'
InfiniteScroll = React.createFactory _InfiniteScroll

import _MasterDetailItem from './components/MasterDetailItem'
MasterDetailItem = React.createFactory _MasterDetailItem

import _MasterDetailGroup from './components/MasterDetailGroup'
MasterDetailGroup = React.createFactory _MasterDetailGroup

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import styles from './index.styl'

export {default as MasterDetailItem} from './components/MasterDetailItem'
export MasterDetailContentWrapper = ({children, className}) ->
	div {
		className: cnames styles.detailContent, className
	}, children
export default class MasterDetail extends React.Component
	@propTypes:
		items: PropTypes.array
		groups: PropTypes.array
		uncategorized: PropTypes.object
		onItemEvent: PropTypes.shape
			onClick: PropTypes.func
			onDoubleClick: PropTypes.func
			onMouseEnter: PropTypes.func
			onMouseLeave: PropTypes.func
		selected: PropTypes.array
		isListCollapsable: PropTypes.bool
	@defaultProps:
		listHidden: false
		contentHidden: false
		selected: []
		uncategorized:
			key: 'uncategorized'
			title: 'Uncategorized'
	constructor: (props) ->
		super props
		@scrollRef = React.createRef()
		@state = {}
	checkItemEvent: (handler, item) ->
		if handler?
			(event) ->
				handler {event, item}
	renderItem: (item) =>
		MasterDetailItem {
			...item
			key: item.id
			..._.reduce @props.onItemEvent, (acc, func, eventName) ->
				acc[eventName] = (event) -> func {event, item}
				acc
			, {}
			selected: item.id in @props.selected
		}
	render: ->
		# Match items 'group' property against available groups
		# Uncategorized items will be displayed at the bottom of the list after space break
		groupsKeys = _.map @props.groups, 'key'
		groupedContent = _.reduce @props.items, (acc, item) ->
			if item.group in groupsKeys
				acc.groups[item.group] = [] if !acc.groups[item.group]?
				acc.groups[item.group].push item
			else
				acc.uncategorized.push item
			acc
		,
			groups: {}
			uncategorized: []
		groupedContent.groups = _.reduce groupedContent.groups, (acc, group, groupKey) ->
			acc[groupKey] = group if !_.isEmpty group
			acc
		, {}

		hasGroups = !_.isEmpty groupedContent.groups
		div {
			className: cnames [
				@props.className
				styles.masterDetail
				if !hasGroups then styles.withoutGroups
			]
		},
			do =>
				if !@props.listHidden
					div {className: cnames [
						styles.list
						'bevy-masterDetail-list'
						@props.listClassName
					]},
						InfiniteScroll {
							className: styles.infiniteScroll
							..._.pick @props, [
								'hasMore'
								'loader'
								'loadMore'
							]
							useWindow: false
						},
							if @props.loading then Spinner {}
							else
								Fragment {},
									do =>
										if hasGroups
											_.map groupedContent.groups, (items, groupKey) =>
												Fragment {key: groupKey},
													MasterDetailGroup {
														isCollapsable: @props.isListCollapsable
														..._.find(@props.groups, key: groupKey)
													},
														_.map items, @renderItem
									do =>
										if hasGroups and !_.isEmpty groupedContent.uncategorized
											MasterDetailGroup {
												isCollapsable: @props.isListCollapsable
												...@props.uncategorized
											}, _.map groupedContent.uncategorized, @renderItem
										else
											_.map groupedContent.uncategorized, @renderItem
			do =>
				if !@props.contentHidden
					div {
						className: cnames [
							styles.detail
							@props.contentClassName
							if @props.listHidden then styles.detailFull
							'bevy-masterDetail-detail'
						]
					},
						div {
							className: cnames styles.detailWrapper
						},
							# if @props.loading then Spinner {}
							@props.children
