import _ from 'lodash'
import React from 'react'
import moment from 'moment-mini'
import {FormatterConsumer} from 'libs/formatter'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'

# Renderables
import { div, span, img, p } from 'react-dom-factories'

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import { Link as _Link } from 'react-router-dom'
Link = React.createFactory _Link

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

# Data
import trLegal from 'data/local/legal'

# Styles
import styles from './columns.styl'
import {
	userCheck
} from 'react-icons-kit/feather'

projectLeader =
	key: 'projectLeader'
	label: 'Project Leader'
	width: 80
	constWidth: true
	isSortable: false
	render: ({ item }) ->
		div {className: cnames styles.compactText, styles.centered},
			if (!_.isEmpty item.roles) and _.some item.roles, (role) -> role.name is 'Project Leader'
				Icon {icon: userCheck, size: 11}

name =
	key: 'name'
	label: 'Name'
	sortKey: 'Name'
	width: 100
	isSortable: false
	render: ({ item }) ->
		# console.log item
		# Tooltip
		# 	className: styles.tooltip
		# 	content: item.name
		# 	showTimeout: 200
		# 	hideTimeout: 5000
		# ,
			span {className: cnames styles.compactText},
				if item.user.name? then item.user.name else '-'

email =
	key: 'email'
	label: 'E-mail'
	sortKey: 'Email'
	width: 100
	isSortable: false
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.user.email? then item.user.email else '-'

# id =
# 	key: 'id'
# 	label: 'ID delet dis'
# 	width: 200
# 	isSortable: false
# 	render: ({ item }) ->
# 		span {className: cnames styles.compactText},
# 			if item.user.id? then item.user.id else '-'

# groups =
# 	key: 'groups'
# 	label: 'Groups'
# 	width: 30
# 	render: ({ item }) ->
# 		span {className: cnames styles.compactText},
# 			if !_.isEmpty item.user.groups then _.join (_.map item.user.groups, 'name'), ',' else '-'

projectsPermissions =
	key: 'roles'
	label: 'Roles'
	width: 200
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if !_.isEmpty item.roles then _.join (_.map item.roles, 'name'), ', ' else '-'

export USERS_COLUMNS = do ->
	columns =
		[
			{ ...projectLeader, selectedByDefault: true}
			{ ...name, selectedByDefault: true }
			{ ...email, selectedByDefault: true}
			# { ...id, selectedByDefault: true}
			# { ...groups, selectedByDefault: true}
			{ ...projectsPermissions, selectedByDefault: true}
		]
	_.map columns, (column) -> {...column, selected: column.selectedByDefault}
