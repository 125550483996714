# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import DataAttribute from '@bevy/data-attribute'
import moment from 'moment-mini'


# Renderables
import { div, input, span } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

# TODO: <NCU-0> After dependencies this feature needs to be re-checked
import {
	DropdownMenuStateless as _DropdownMenuStateless
} from '@atlaskit/dropdown-menu'
DropdownMenuStateless = React.createFactory _DropdownMenuStateless

import _Tooltip from '@atlaskit/tooltip'
Tooltip = React.createFactory _Tooltip

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _KPI from '@bevy/kpi'
KPI = React.createFactory _KPI

import _Button from '@bevy/button'
Button = React.createFactory _Button

import { RadioGroup as _RadioGroup } from '@atlaskit/radio'
RadioGroup = React.createFactory _RadioGroup

import {Checkbox as _Checkbox} from '@atlaskit/checkbox'
Checkbox = React.createFactory _Checkbox

import _Calendar from '@bevy/calendar'
Calendar = React.createFactory _Calendar

import styles from './index.styl'
import {
	alert
	chevronDown
	add as addIcon
} from '@bevy/theme/icons'


class _FilterTileToggle extends React.Component
	@propTypes:
		value: PropTypes.node
		name: PropTypes.node.isRequired
		description: PropTypes.node
		onSelect: PropTypes.func
		isActive: PropTypes.bool
		icon: PropTypes.object
		isLoading: PropTypes.bool
		appearance: PropTypes.oneOf [
			'default'
			'danger'
			'primary'
			'subtle'
			'warning'
			'help'
		]
	constructor: (props) ->
		super props
		@state = {}
	render: ->
		content = div {
			className: cnames [
				@props.className
				styles.FilterTileToggle
				if @props.isActive then styles.active
				if @props.appearance then styles[@props.appearance]
			]
			onClick: (event) => @props.onSelect {event, filter: @props}
		},
			# div {className: cnames styles.value},
			# 	if @props.isLoading
			# 		Spinner {
			# 			size: 'small'
			# 			inline: true
			# 		}
			# 	else
			# 		@props.value
			div {className: cnames styles.label},
				span {className: cnames styles.name}, @props.name
				if @props.icon then span {className: cnames styles.icon},
					Icon {
						...@props.icon
						size: 11
					}
		if @props.description
			Tooltip {
				content: @props.description
			}, content
		else
			content

class _FilterTileBundle extends React.Component
	@propTypes:
		value: PropTypes.node
		name: PropTypes.node.isRequired
		onSelect: PropTypes.func
		isLoading: PropTypes.bool
		isOpen: PropTypes.bool
		appearance: PropTypes.oneOf [
			'default'
			'danger'
			'primary'
			'subtle'
			'warning'
			'help'
		]
		type: PropTypes.oneOf [
			'radio'
			'checklist'
			'date'
		]
		options: PropTypes.arrayOf PropTypes.shape
			name: PropTypes.string
			label: PropTypes.node.isRequired
			isDisabled: PropTypes.bool
			isActive: PropTypes.bool
	constructor: (props) ->
		super props
		@root = React.createRef()
		@innerRoot = React.createRef()
		@state =
			isOpen: @props.isOpen || false
	componentWillUnmount: ->
		if @state.isOpen
			@unmountListeners()
	mountListeners: =>
		document.addEventListener 'click', @handleClick
		document.addEventListener 'scroll', @handleScroll
		document.addEventListener 'keydown', @handleEsc
	unmountListeners: =>
		document.removeEventListener 'click', @handleClick
		document.removeEventListener 'scroll', @handleScroll
		document.removeEventListener 'keydown', @handleEsc
	handleClick: (event) =>
		return null if !@root?.current?
		wasOutside = !(@root.current.contains event.target)
		wasInside = (@innerRoot.current.contains event.target)
		if wasOutside && @state.isOpen
			@tryClosing()
		else if !wasOutside && !wasInside && @state.isOpen
			@tryClosing()
	handleScroll: (event) =>
		wasOutside = !(@root.current.contains event.target)
		if wasOutside && @state.isOpen
			@tryClosing()
	handleEsc: (event) =>
		if event.key is 'Escape'
			@tryClosing()
	tryClosing: ->
		if @config.requestClose
			@config.onRequestClose()
		else
			@close()
	open: ({event}, content, config) =>
		event.preventDefault()
		@mountListeners()

		@event = event
		@content = content
		@config = _.defaults config,
			requestClose: false
			onRequestClose: ->
			onClose: ->

		@setState
			isOpen: true
			clickPosition:
				x: event.clientX
				y: event.clientY
	close: =>
		if @state.isOpen
			@unmountListeners()
			@setState
				isOpen: false
				clickPosition: null
			@config.onClose()
	render: ->
		selectedOptions = _.filter(@props.options, isActive: true)
		selectedOption = _.first selectedOptions
		if !selectedOption?
			selectedOption = _.first @props.options
		div { ref: @root, className: styles.FilterTileBundle},
			DropdownMenuStateless
				isOpen: @state.isOpen
				trigger: FilterTileToggle {
					..._.omit @props, ['isOpen', 'options', 'onSelect']
					name: if !_.isEmpty(selectedOptions)
							_.map(selectedOptions, 'label').join(', ')
						else if @props.date?
							if @props.endingDate?
								moment.unix(@props.date).subtract(1, 'day').format('DD/MM/YYYY')
							else
								moment.unix(@props.date).format('DD/MM/YYYY')
						else
							@props.name
					icon: chevronDown
					onSelect: @open
				}
			,
				div { ref: @innerRoot, className: styles.droplist },
					if @props.type is 'radio'
						RadioGroup
							value: selectedOption.value
							options: @props.options
							onChange: (event) =>
								@props.onSelect {event, filter: @props, item: _.find @props.options, value: event.target.value}
					else if @props.type is 'date'
						Calendar
							onConfirm: (event) =>
								if @props.endingDate?
									event = moment.unix(event).add(1, 'day').unix()
								@props.onSelect {event, filter: @props, item: {label: event, value: event}}
							date: if @props.endingDate?
									moment.unix(@props.date).subtract(1, 'day').unix()
								else
									@props.date
					else
						_.map @props.options, (option, index) =>
							Checkbox {
								key: index
								...option
								isChecked: option.isActive is true
								onChange: (event) =>
									@props.onSelect {event, filter: @props, item: _.find @props.options, value: event.target.value}
							}


# Supports toggle and bundle
class _FilterTile extends React.Component
	constructor: (props) ->
		super props
		@state = {}
	render: ->
		if @props.options? or @props.type is 'date'
			FilterTileBundle @props
		else
			FilterTileToggle @props


class _SearchBox extends React.Component
	constructor: (props) ->
		super props
		@state =
			value: ''

	componentDidMount: (props) ->
		document.addEventListener 'keydown', @handleEnterClick

	componentWillUnmount: (props) ->
		document.removeEventListener 'keydown', @handleEnterClick

	handleEnterClick: ({key}) =>
		if key is 'Enter'
			@props.onChange @state.value

	render: ->
		Fragment {},
			span {className: styles.note}, 'Search: '
			input
				type: 'search'
				value: @state.value
				placeholder: 'What are you looking for?'
				onChange: (e) =>
					@setState value: e.target.value
					if _.isEmpty e.target.value
						@props.onChange null
				autoFocus: true
				className: styles.SearchBox

FilterTileToggle = React.createFactory _FilterTileToggle
FilterTileBundle = React.createFactory _FilterTileBundle
FilterTile = React.createFactory _FilterTile
SearchBox = React.createFactory _SearchBox

export default class FiltersBar extends React.Component
	@propTypes:
		mode: PropTypes.oneOf ['quick', 'filters', 'search']
		onModeChange: PropTypes.func
		kpi: PropTypes.arrayOf PropTypes.shape _KPI.propTypes
		alert: PropTypes.shape _FilterTileToggle.propTypes
		quick: PropTypes.arrayOf PropTypes.oneOfType [
				PropTypes.shape _FilterTileToggle.propTypes
				PropTypes.shape _FilterTileBundle.propTypes
			]
		search: PropTypes.func


	renderQuickFilters: =>
		div {
			className: cnames styles.quickFilters
		},
			if @props.alert?
				div {className: styles.alerts}, FilterTile {
					...@props.alert
					appearance: 'danger'
					icon: alert
				}
			if @props.quick
				Fragment {},
					div {className: styles.list}, _.map @props.quick, FilterTile
					div {
						className: styles.clear
					},
						Button {
							isDisabled: !@props.onClear?
							onClick: @props.onClear
							tooltip: 'Clear all filters and restore default'
						}, 'Clear filters'

	renderFilters: ->
		#FIXME: TODO: get rid of this
		if @props.legacyFilters?
			@props.legacyFilters {onChange: @props.onChange}
		else
			div {
				className: cnames styles.filters,
			},
				div {className: styles.container},
					Button {
						tooltip: ''
						iconBefore: Icon addIcon
					}
					span {className: styles.note}, 'Add filters to narrow down the range of displayed data.'
				div {className: styles.control},
					Button {}, 'Save filter'
					Button {}, 'Clear'
	renderSearch: ->
		div {
			className: cnames styles.search
		},
			SearchBox onChange: @props.search

	constructor: (props) ->
		super props
		@state = {}
	render: ->
		div {
			className: cnames styles.FiltersBar
		},
			switch @props.mode
				when 'quick' then @renderQuickFilters()
				when 'filters' then @renderFilters()
				when 'search' then @renderSearch()
				else
					null


