###
Editor for dynamic list configuration
###

import _ from 'lodash'
import React from 'react'
import moment from 'moment-mini'
import cnames from 'classnames'
import PropTypes from 'prop-types'


# Renderables
import { div, span } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import { Checkbox as _Checkbox } from '@atlaskit/checkbox'
Checkbox = React.createFactory _Checkbox

import { ButtonGroup as _ButtonGroup } from '@atlaskit/button'
ButtonGroup = React.createFactory _ButtonGroup

import _Button from '@bevy/button'
Button = React.createFactory _Button

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _SidePanel from '@bevy/side-panel'
SidePanel = React.createFactory _SidePanel


# Styles
import styles from './index.styl'
import { chevronDown, chevronUp, arrowRight } from 'react-icons-kit/feather'

# React DnD
import HTML5Backend from 'react-dnd-html5-backend'
import { DndProvider as _DndProvider } from 'react-dnd'
DndProvider = React.createFactory _DndProvider
import { DragSource, DropTarget } from 'react-dnd'

ItemTypes = {
	LIST_ITEM: 'listItem'
}

class DraggableItem extends React.Component
	@propTypes:
		moveItemOnList: PropTypes.func

	render: ->
		@props.connectDropTarget @props.connectDragSource div {
			className: cnames(
				styles.item,
				(if @props.isDragging then styles.isDragging),
				(if @props.isLast then styles.isLast)
				),
			key: @props.index
		},
				if @props.isOver
					div className: cnames(styles.dragDummy)

				if @props.isLast isnt true
					@props.connectDragPreview div className: cnames(
						styles.draggableItem,
						(if @props.isDragging then styles.isDragging))
						, @props.children

# Source
dragSource =
	beginDrag: (props) -> {
		itemIndex: props.itemIndex
	}
	endDrag: (props, monitor) ->
		if monitor.getDropResult()
			props.moveItemOnList(props.itemIndex, monitor.getDropResult().targetIndex)

dragSourceCollect = (connect, monitor) ->
	connectDragSource: connect.dragSource()
	connectDragPreview: connect.dragPreview()
	isDragging: monitor.isDragging()

# Target
dropTarget =
	drop: (props, monitor) ->
		targetIndex: props.itemIndex

dropTargetCollect = (connect, monitor) ->
	connectDropTarget: connect.dropTarget()
	isOver: monitor.isOver()
	canDrop: monitor.canDrop()

# Force refresh
options =
	arePropsEqual: (props, otherProps) -> false


# Export
DraggableItem = DragSource(ItemTypes.LIST_ITEM, dragSource, dragSourceCollect, options)(DraggableItem)
DraggableItem = DropTarget(ItemTypes.LIST_ITEM, dropTarget, dropTargetCollect, options)(DraggableItem)
DraggableItem = React.createFactory DraggableItem

export default class ListEditor extends React.Component
	@propTypes:
		items: PropTypes.array
		onApply: PropTypes.func

	constructor: (props) ->
		super props
		@state =
			items: _.cloneDeep @props.items
			isOpen: false

	openTableEditor: () => @setState isOpen: true

	moveItemOnList: (from, to) =>
		if from - to isnt -1
			if from < to
				to--
			items = _.cloneDeep @state.items
			items.splice(to, 0, items.splice(from, 1)[0])
			@setState items: items

	changeItemSelection: (item) =>
		newItems = _.cloneDeep @state.items
		_.find(newItems, item).selected = !item.selected
		@setState items: newItems

	restoreToDefault: =>
		defaults = _.map @state.items, (item) -> {...item, selected: item.selectedByDefault}
		@props.onRestore defaults
		@setState items: defaults, isOpen: false

	render: ->
		DndProvider {backend: HTML5Backend},
			SidePanel
				active: @state.isOpen
				renderCloseButton: false
				className: styles.sidePanel
				onBlanketClick: => @setState isOpen: false
				positionFixed: true
			,
				div {
					className: cnames styles.base
				},
					div {className: styles.headerBar},
						div {className: styles.header}, 'Edit table columns'
						Button {
							appearance: 'subtle'
							onClick: @restoreToDefault
						}, 'Restore to default'
						# Icon {className: styles.close, icon: arrowRight, size: 20, onClick: () => @props.onApply @state.items}
					div {className: styles.body},
						_.map @state.items, (column, index) =>
							div {key: column.key},
								DraggableItem
									moveItemOnList: @moveItemOnList
									itemIndex: index
								,
									div {className: styles.itemContent, onClick: () => @changeItemSelection(column)},
										Checkbox
											onClick: => @changeItemSelection(column)
											isChecked: column.selected
										span {}, column.label
										div {className: styles.actions},
											Button
												appearance: 'subtle'
												onClick: (e) =>
													e.stopPropagation()
													@moveItemOnList(index, index - 1)
												isDisabled: index is 0
											,
												Icon {className: styles.arrow, icon: chevronUp, size: 12}
											Button
												appearance: 'subtle'
												onClick: (e) =>
													e.stopPropagation()
													@moveItemOnList(index, index + 1)
												isDisabled: index is @state.items.length - 1
											,
												Icon {className: styles.arrow, icon: chevronDown, size: 12}
						DraggableItem
							moveItemOnList: ->
							itemIndex: _.size @state.items
							isLast: true
					div {className: styles.footer},
						ButtonGroup {},
							Button
								appearance: 'primary'
								onClick: () =>
									@props.onApply @state.items
									@setState isOpen: false
							, 'Apply'
							Button {onClick: () => @setState isOpen: false}, 'Cancel'

			@props.children @openTableEditor

# export default DragDropContext(HTML5Backend)(ListEditor)
