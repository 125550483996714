"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.scopes = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _helpers = require("../helpers");

var _Project = require("./Project");

var _LegalDocument = require("./LegalDocument");

var _Organization = require("./Organization");

var _User = require("./User");

var _SafetyVisit = require("./SafetyVisit");

var _SafetyObservation = require("./SafetyObservation");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Generated by CoffeeScript 2.4.1

/*
Roles
*/
var SafetyUserPermissions;

/*
Project permissions model
*/
var scopes = (0, _helpers.makeDefaultSubject)('Project', {
  Manage: {
    actions: 'crud'
  },
  Create: {
    actions: 'create'
  },
  Update: {
    actions: 'update'
  },
  Delete: {
    actions: 'delete'
  },
  Read: {
    actions: 'read'
  },
  ReadRevenue: {
    actions: 'read',
    fields: 'Revenue'
  },
  ReadCosts: {
    actions: 'read',
    fields: 'Costs'
  },
  ReadWhatIf: {
    actions: 'read',
    fields: 'WhatIf'
  },
  ReadSafety: {
    actions: 'read',
    fields: 'Safety'
  },
  ReadSafetyRD: {
    actions: 'read',
    fields: 'Safety',
    conditions: {
      branch: 'RD'
    }
  },
  ReadSafetyCDE: {
    actions: 'read',
    fields: 'Safety',
    conditions: {
      branch: 'CDE'
    }
  },
  ReadTQT: {
    actions: 'read',
    fields: 'TQT'
  },
  ReadLegal: {
    actions: 'read',
    fields: 'Legal'
  },
  ReadOrganizations: {
    actions: 'read',
    fields: 'Organizations'
  },
  ReadPersonnel: {
    actions: 'read',
    fields: 'Personnel'
  },
  ReadAllHubs: [{
    actions: 'read',
    fields: 'Personnel'
  }, {
    actions: 'read',
    fields: 'Revenue'
  }, {
    actions: 'read',
    fields: 'Costs'
  }, {
    actions: 'read',
    fields: 'WhatIf'
  }, {
    actions: 'read',
    fields: 'Safety'
  }, {
    actions: 'read',
    fields: 'TQT'
  }, {
    actions: 'read',
    fields: 'Legal'
  }, {
    actions: 'read',
    fields: 'Organizations'
  }, {
    actions: 'read',
    fields: 'Personnel'
  }]
});
exports.scopes = scopes;

SafetyUserPermissions = function ({
  user,
  parent: project,
  membership,
  resolved,
  db,
  cache
}) {
  return [{
    scopes: [_Project.scopes.ReadSafety],
    conditions: _objectSpread({}, (0, _helpers.identityCondition)(resolved, 'project', project))
  }, {
    scopes: [_SafetyVisit.scopes.Self.Read, _SafetyVisit.scopes.Members.Join],
    conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project), {
      status: {
        $in: ['InLobby']
      }
    })
  }, {
    scopes: [_SafetyObservation.scopes.Self.Create],
    conditions: _objectSpread({}, (0, _helpers.projectAssociationCondition)(resolved, project), {
      $or: [{
        visitID: {
          $exists: false
        }
      }, {
        visitID: null
      }]
    })
  }];
};

var _default = {
  // Name of the GraphQL subject
  // TODO: Add time based conditions
  // timestamp:
  // 	$lte: Date.now()
  // 	$gte: Date.now() - 1day
  name: 'Project',
  // Database collection
  collection: 'projects',
  // Name used in making global id's
  globalIDName: 'project',
  // Available actions on root leve
  actions: ['crud', 'create', 'read', 'update', 'delete'],
  // Alias for create, read, update, delete
  // Model fields with appropiate actions
  fields: {},
  // Available scopes with implicit subject as a model.name
  scopes,
  // Additional fields requested when fetching items for attached and members roles
  projection: {
    branch: 1
  },
  // Type of models that can become a member of this model without specified role
  accepts: [],
  // Entity based roles default and assignable
  roles: {
    // Roles granted to all users
    default: [],
    // Roles assignable to the entity that will be transfered to the User
    attached: [],
    // Roles assignable to the members (Users) of this entity
    members: [...(0, _helpers.makeSetOfAccepts)(['UserGroup'], [{
      name: 'Legal access group',
      permissions: function ({
        user,
        parent: project,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_LegalDocument.scopes.Create, _LegalDocument.scopes.Read, _User.scopes.Read],
          conditions: resolved ? {
            projectID: (0, _helpers.makeGlobalID)('project', project._id)
          } : {
            projectID: project._id
          }
        }, {
          scopes: [_Project.scopes.ReadPersonnel, _Project.scopes.ReadLegal, _Project.scopes.ReadOrganizations],
          conditions: resolved ? {
            id: (0, _helpers.makeGlobalID)('project', project._id)
          } : {
            _id: project._id
          }
        }];
      }
    }]), ...(0, _helpers.makeSetOfAccepts)(['User'], [{
      name: 'Project Leader',
      singular: true,
      permissions: function ({
        user,
        parent: project,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_LegalDocument.scopes.Manage, _Organization.scopes.Manage, _User.scopes.Read],
          conditions: {
            $or: [{
              projectID: (0, _helpers.makeGlobalID)('project', project._id)
            }, {
              projectID: project._id
            }]
          }
        }, {
          scopes: [_Project.scopes.ReadPersonnel, _Project.scopes.ReadLegal, _Project.scopes.ReadOrganizations],
          conditions: {
            $or: [{
              id: (0, _helpers.makeGlobalID)('project', project._id)
            }, {
              _id: project._id
            }]
          }
        }];
      }
    }, {
      name: 'Project Manager',
      permissions: function ({
        user,
        parent: project,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_LegalDocument.scopes.Manage, _Organization.scopes.Manage, _User.scopes.Read],
          conditions: {
            $or: [{
              projectID: (0, _helpers.makeGlobalID)('project', project._id)
            }, {
              projectID: project._id
            }]
          }
        }, {
          scopes: [_Project.scopes.ReadPersonnel, _Project.scopes.ReadLegal, _Project.scopes.ReadOrganizations],
          conditions: {
            $or: [{
              id: (0, _helpers.makeGlobalID)('project', project._id)
            }, {
              _id: project._id
            }]
          }
        }];
      }
    }, {
      name: 'Document Manager',
      permissions: function ({
        user,
        parent: project,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_LegalDocument.scopes.Manage, _Organization.scopes.Manage, _User.scopes.Read],
          conditions: {
            $or: [{
              projectID: (0, _helpers.makeGlobalID)('project', project._id)
            }, {
              projectID: project._id
            }]
          }
        }, {
          scopes: [_Project.scopes.ReadPersonnel, _Project.scopes.ReadLegal, _Project.scopes.ReadOrganizations],
          conditions: {
            $or: [{
              id: (0, _helpers.makeGlobalID)('project', project._id)
            }, {
              _id: project._id
            }]
          }
        }];
      }
    }, {
      name: 'Regional Director'
    }, {
      name: 'Senior Regional Asset Coordinator'
    }, {
      name: 'HSE Manager'
    }, {
      name: 'Controlling Manager'
    }, {
      name: 'Leasing Negotiator'
    }, {
      name: 'Leasing Manager'
    }, {
      name: 'Asset Coordinator'
    }, {
      name: 'Marketing and Communication Manager'
    }, {
      name: 'Asset Manager'
    }, {
      name: 'Facility Manager'
    }, {
      name: 'Legal User',
      permissions: function ({
        user,
        parent: project,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_LegalDocument.scopes.Create, _User.scopes.Read],
          conditions: {
            $or: [{
              projectID: (0, _helpers.makeGlobalID)('project', project._id)
            }, {
              projectID: project._id
            }]
          }
        }, {
          scopes: [_Project.scopes.ReadPersonnel, _Project.scopes.ReadLegal, _Project.scopes.ReadOrganizations],
          conditions: {
            $or: [{
              id: (0, _helpers.makeGlobalID)('project', project._id)
            }, {
              _id: project._id
            }]
          }
        }];
      }
    }, {
      name: 'Spectator',
      permissions: function ({
        user,
        parent: project,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_User.scopes.Read],
          conditions: {
            $or: [{
              projectID: (0, _helpers.makeGlobalID)('project', project._id)
            }, {
              projectID: project._id
            }]
          }
        }, {
          scopes: [_Project.scopes.ReadPersonnel, _Project.scopes.ReadLegal, _Project.scopes.ReadOrganizations],
          conditions: {
            $or: [{
              id: (0, _helpers.makeGlobalID)('project', project._id)
            }, {
              _id: project._id
            }]
          }
        }];
      }
    }, {
      name: 'Finance Tester',
      permissions: function ({
        user,
        parent: project,
        membership,
        resolved,
        db,
        cache
      }) {
        return [{
          scopes: [_Project.scopes.ReadRevenue, _User.scopes.Read],
          conditions: {
            $or: [{
              projectID: (0, _helpers.makeGlobalID)('project', project._id)
            }, {
              projectID: project._id
            }]
          }
        }, {
          scopes: [_Project.scopes.ReadPersonnel, _Project.scopes.ReadOrganizations],
          conditions: {
            $or: [{
              id: (0, _helpers.makeGlobalID)('project', project._id)
            }, {
              _id: project._id
            }]
          }
        }];
      }
    }, {
      name: 'Safety User',
      description: 'Everyone in project with basic access to safety aspect of the project with permissions to:\n	* create non-visit observations\n	* join visits',
      permissions: SafetyUserPermissions
    }, {
      name: 'Safety Inspector',
      description: 'Person responsible for safety aspact of the project with main permissions for:\n	* create safety visits\n	* becoming default visit guide',
      permissions: async function ({
        user,
        parent: project,
        membership,
        resolved,
        db,
        cache
      }) {
        var permissions;
        permissions = _lodash.default.compact([// Allow RDE inspectors to record training
        project.branch === 'RD' ? {
          scopes: [_SafetyVisit.scopes.Self.RecordTraining],
          conditions: resolved ? {
            'project.id': {
              $exists: false
            }
          } : {
            projectID: {
              $exists: false
            }
          }
        } : void 0, {
          scopes: [_SafetyVisit.scopes.Self.Create],
          conditions: _objectSpread({}, (0, _helpers.projectAssociationCondition)(resolved, project), {}, project.branch === 'CDE' ? {
            recordType: 'OnSite'
          } : {})
        }, {
          scopes: [_SafetyVisit.scopes.Self.Read],
          conditions: _objectSpread({}, (0, _helpers.projectAssociationCondition)(resolved, project), {}, _helpers.isSelfDeletedCondition)
        }, {
          scopes: [_SafetyObservation.scopes.Self.Read, _SafetyObservation.scopes.Details.Update, _SafetyObservation.scopes.Ratings.Update, _SafetyObservation.scopes.Attachments.Manage],
          conditions: _objectSpread({}, (0, _helpers.projectAssociationCondition)(resolved, project), {}, _helpers.isSelfDeletedCondition)
        }, {
          scopes: [_SafetyObservation.scopes.Review.Check],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project), {
            rating: {
              $ne: 'Positive'
            },
            status: 'PendingCheck'
          })
        }, {
          scopes: [_SafetyObservation.scopes.Review.Uncheck],
          conditions: _objectSpread({}, _helpers.isSelfDeletedCondition, {}, (0, _helpers.projectAssociationCondition)(resolved, project), {
            rating: {
              $ne: 'Positive'
            },
            status: 'Checked'
          })
        }]);
        return [// ,
        // 	scopes: [
        // 		SafetyVisitScopes.Self.Share
        // 	]
        // 	conditions: {
        // 		...projectAssociationCondition resolved, project
        // 		...isSelfDeletedCondition
        // 		TODO: rolesToAdd: 'SafetyVisitReader'
        // 	}
        // ,
        // 	scopes: [
        // 		SafetyObservationScopes.Self.Share
        // 	]
        // 	conditions: {
        // 		...projectAssociationCondition resolved, project
        // 		...isSelfDeletedCondition
        // 		TODO: rolesToAdd: 'SafetyObservationReader'
        // 	}
        ...permissions, ...(await SafetyUserPermissions({
          user,
          parent: project,
          membership,
          resolved,
          db,
          cache
        }))];
      }
    }])]
  }
};
exports.default = _default;