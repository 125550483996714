###
S360 application / platform layout and navigation
###

# Libs
import _ from 'lodash'
import React from 'react'
import PageTitle from 'components/PageTitle'

# Renderable
import { div, p, ul, li, img, span, iframe } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import {
	Route as _Route
	Switch as _Switch
} from 'react-router-dom'
Route = React.createFactory _Route
Switch = React.createFactory _Switch

import {InvalidHubState as _InvalidHubState} from 'components/ErrorDisplay'
InvalidHubState = React.createFactory _InvalidHubState

import AccountsHub from '../pages/Accounts'
import Dashboard from '../pages/Dashboard'
import Groups from '../pages/Groups'
import ProjectsHub from '../pages/Projects'
import ProjectDashboardOld from '../pages/ProjectDashboardOld'
import ProjectDashboard from '../pages/ProjectDashboard'
import ProjectRevenue from '../pages/ProjectRevenue'
import ProjectCosts from '../pages/ProjectCosts'
import ProjectLegal from '../pages/ProjectLegal'
import ProjectUsers from '../pages/ProjectUsers'
import ProjectCompanies from '../pages/ProjectCompanies'
import ProjectScenarios from '../pages/ProjectScenarios'
import ProjectSafety from '../pages/ProjectSafety'
import Users from '../pages/Users'

# Styles
import styles from './index.styl'

export default class MainRouter extends React.Component
	render: ->
		pageProps = @props
		Fragment {},
			PageTitle 'Skanska 360'
			Switch {},
				Route
					exact: true
					path: '/'
					render: (props) ->
						React.createElement Dashboard, {...props, ...pageProps}
				Route
					exact: true
					path: '/accounts'
					render: (props) ->
						React.createElement AccountsHub, {...props, ...pageProps}
				Route
					path: '/groups/:id?'
					render: (props) ->
						React.createElement Groups, {...props, ...pageProps}
				Route
					path: '/users'
					render: (props) ->
						React.createElement Users, {...props, ...pageProps}
				Route
					exact: true
					path: '/projects'
					render: (props) ->
						React.createElement ProjectsHub, {...props, ...pageProps}
				Route
					exact: true
					path: '/projects/:project/new'
					render: (props) ->
						if props.match.params.project is 'p180' #TODO: REMOVE THIS
							React.createElement ProjectDashboardOld, {...props, ...pageProps}
						else
							div {className: styles.dashboard},
								React.createElement ProjectDashboard, {...props, ...pageProps}
				Route
					exact: true
					path: '/projects/:project'
					render: (props) ->
						newProjectsSlugs = ['p180', 'centrum-poludnie', 'centrum-poludnie-3', 'centrum-poludnie-5', 'aleja-pokoju']
						if props.match?.params?.project? and props.match.params.project in newProjectsSlugs
							React.createElement ProjectDashboardOld, {...props, ...pageProps}
						else
							React.createElement ProjectDashboard, {...props, ...pageProps}
				Route
					path: '/projects/:project/revenue'
					render: (props) ->
						React.createElement ProjectRevenue, {...props, ...pageProps}
				Route
					path: '/projects/:project/costs'
					render: (props) ->
						React.createElement ProjectCosts, {...props, ...pageProps}
				Route
					path: '/projects/:project/legal'
					render: (props) ->
						React.createElement ProjectLegal, {...props, ...pageProps}
				Route
					path: '/projects/:project/users'
					render: (props) ->
						React.createElement ProjectUsers, {...props, ...pageProps}
				Route
					path: '/projects/:project/companies'
					render: (props) ->
						React.createElement ProjectCompanies, {...props, ...pageProps}
				Route
					path: '/projects/:project/scenarios'
					render: (props) ->
						React.createElement ProjectScenarios, {...props, ...pageProps}
				Route
					path: '/projects/:project/safety'
					render: (props) ->
						React.createElement ProjectSafety, {...props, ...pageProps}
				Route
					path: '/projects/:project/'
					exact: false
					render: (props) ->
						InvalidHubState redirectLink: "/projects/#{props.match.params.project}"
				Route
					path: '/'
					exact: false
					render: (props) ->
						InvalidHubState redirectLink: "/"

