###
Table Layout
###

# Libs
import _ from 'lodash'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'

# Renderable
import { div, h1 } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import { Checkbox as _Checkbox } from '@atlaskit/checkbox'
Checkbox = React.createFactory _Checkbox

import _Spinner from '@atlaskit/spinner'
Spinner = React.createFactory _Spinner

import { Box as _SkeletonBox } from '@bevy/skeleton'
SkeletonBox = React.createFactory _SkeletonBox

import _AutoSizer from 'react-virtualized-auto-sizer'
AutoSizer = React.createFactory _AutoSizer

import _InfiniteLoader from 'react-window-infinite-loader'
InfiniteLoader = React.createFactory _InfiniteLoader

import _InfiniteScroll from 'react-infinite-scroller'
InfiniteScroll = React.createFactory _InfiniteScroll

import { FixedSizeList as _List, areEqual } from 'react-window'
List = React.createFactory _List

import {
	ScrollSync as _ScrollSync,
	ScrollSyncPane as _ScrollSyncPane
} from 'react-scroll-sync'
ScrollSync = React.createFactory _ScrollSync
ScrollSyncPane = React.createFactory _ScrollSyncPane


# Styles
import styles from './index.styl'


class TableCell extends React.PureComponent
	render: ->
		@props.column.cell(@props.row)


class Row extends React.Component
	render: ->
		{ index } = @props
		{ columns, prefix, suffix, items, selected, onItemEvent, rowClassName } = @props.data
		item = if items[index]? then items[index] else null
		isSelected = item? and _.includes selected, item.id
		if rowClassName? then rowClassName = rowClassName {...item, isSelected}, index
		div {
			style: {..._.omit @props.style, ['width'], minWidth: '100%'}
			className: cnames [
				styles.row
				if index % 2 then styles.odd else styles.even
			]
			onClick: (event) ->
				if onItemEvent?.onClick?
					onItemEvent.onClick {event, item, index}
			onDoubleClick: ->
				if onItemEvent?.onDoubleClick?
					onItemEvent.onDoubleClick {event, item, index}
			onMouseEnter: (event) ->
				if onItemEvent?.onMouseEnter?
					onItemEvent.onMouseEnter {event, item, index}
			onMouseLeave: (event) ->
				if onItemEvent?.onMouseLeave?
					onItemEvent.onMouseLeave {event, item, index}
		},

			Fragment {},
				do => if prefix?
					div {
						className: cnames [
							styles.cell
							styles.prefix
							rowClassName
						]
						style:
							minWidth: prefix.width
							maxWidth: prefix.width
					}, do => if !item?
						SkeletonBox className: styles.placeholder
					else
						prefix.render { item, index }

				_.map columns, (column) =>
					div {
						key: column.key
						className: cnames [
							styles.cell
							styles.column
							rowClassName
						]
						style:
							if column.constWidth is true
								minWidth: column.width
								maxWidth: column.width
							else
								width: column.width
					}, do => if !item?
						SkeletonBox className: styles.placeholder
					else
						column.render { item, index }

				do => if suffix?
					div {
						className: cnames [
							styles.cell
							styles.suffix
							rowClassName
						]
						style: width: suffix.width
					}, do => if !item?
						SkeletonBox className: styles.placeholder
					else
						suffix.render { item, index }


export default class Table extends React.Component
	@propTypes:
		items: PropTypes.array
		onItemEvent: PropTypes.shape
			onClick: PropTypes.func
			onDoubleClick: PropTypes.func
			onMouseEnter: PropTypes.func
			onMouseLeave: PropTypes.func
		selected: PropTypes.array
	@defaultProps:
		selected: []

	constructor: (props) ->
		super props

	handleRowSelect: (row, e) =>
		if e? and (e.ctrlKey or e.metaKey)
			if row.id in @props.selectedRows
				@props.onSelectionChange _.without(@props.selectedRows, row.id)
			else
				@props.onSelectionChange [...@props.selectedRows, row.id]
		else
			if row.id in @props.selectedRows and _.size(@props.selectedRows) is 1
				@props.onSelectionChange []
			else
				@props.onSelectionChange row.id

	handleCheckboxSelect: (row, e) =>
		e.stopPropagation()
		if row.id in @props.selectedRows
			@props.onSelectionChange _.without(@props.selectedRows, row.id)
		else
			@props.onSelectionChange [...@props.selectedRows, row.id]

	isItemLoaded: (index) =>
		@props.items[index]?

	createItemData: _.memoize (args) ->
		_.pick args, ['prefix', 'suffix', 'columns', 'items', 'selected', 'onItemEvent', 'rowClassName']

	loadMoreProxy: (startIndex, stopIndex) =>
		skip = startIndex
		limit = stopIndex - startIndex + 1
		if @props.loadMore?
			@props.loadMore { skip, limit }

	getItemCount: () =>
		currentItemsCount = _.size @props.items
		overscanCount = if @props.overscanCount? then @props.overscanCount else 30
		if currentItemsCount?
			_.min [(currentItemsCount + overscanCount), @props.itemsCount]
		else
			100

	renderHeaders: ->
		Fragment {},
			do => if @props.prefix?
				div {
					className: cnames [
						styles.cell
						styles.prefix
						if !@props.prefix.renderHeader? then styles.spacer
					]
					style: width: @props.prefix.width
				}, do => if @props.prefix.renderHeader?
					@props.prefix.renderHeader()

			_.map @props.columns, (column) =>
				div {
					key: column.key
					className: cnames [
						styles.cell
						styles.column
						if column.isSortable then styles.sortableHeader
						if @props.sortKey is column.sortKey
							if @props.sortOrder is 'asc' then styles.sortedAsc
							else if @props.sortOrder is 'desc' then styles.sortedDesc
					]
					style:
						if column.constWidth is true
							maxWidth: column.width
							minWidth: column.width
						else
							width: column.width
				},
					if column.label?
						h1 {
							className: styles.label
							onClick: if column.isSortable then =>
								@props.onSortChange(column.sortKey, if (@props.sortKey is column.sortKey) and (@props.sortOrder is 'asc') then 'desc' else 'asc')
						}, column.label

			do => if @props.suffix?
				div {
					className: cnames [
						styles.cell
						styles.suffix
						if !@props.suffix.renderHeader? then styles.spacer
					]
					style: width: @props.suffix.width
				}, do => if @props.suffix.renderHeader?
						@props.suffix.renderHeader()

	render: ->
		itemCount = @getItemCount()
		ScrollSync {},
			div {
				className: cnames [
					@props.className
					styles.container
					'bevy-table'
				]
			},
				div {className: cnames styles.tableWrapper, if !@props.hideBorders? then styles.borders},
					div {className: styles.headersWrapper},
						ScrollSyncPane {},
							div {className: styles.headers}, @renderHeaders()
					div {className: styles.body},
						if (itemCount is 0) and @props.emptyState?
							@props.emptyState()
						else
							AutoSizer {}, ({ height, width }) =>
								InfiniteLoader
									isItemLoaded: @isItemLoaded
									itemCount: itemCount
									loadMoreItems: @loadMoreProxy
									minimumBatchSize: if @props.batchSize? then @props.batchSize else 30
								,
									({ onItemsRendered, ref }) =>
										ScrollSyncPane {},
											List
												height: height
												width: width
												itemCount: itemCount
												itemData: @createItemData @props
												itemSize: @props.rowHeight
												onItemsRendered: onItemsRendered
												ref: ref
												overscanCount: if @props.overscanCount? then @props.overscanCount else 30
											,
												Row
