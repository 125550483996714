###
Dropdown menu component
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import DataAttribute from '@bevy/data-attribute'

# Renderable
import { div } from 'react-dom-factories'

# Styles
import styles from './index.styl'

export default class DropdownMenu extends React.PureComponent
	@propTypes:
		trigger: PropTypes.node
		closeOnSelect: PropTypes.bool
		options: PropTypes.arrayOf PropTypes.shape
			label: PropTypes.string
			onClick: PropTypes.func

	@defaultProps:
		onOpenChange: ->
		closeOnSelect: false
		maxMenuWidth: 160
		maxMenuHeight: 300

	constructor: (props) ->
		super props
		@trigger = React.createRef()
		@menu = React.createRef()
		@state =
			isOpen: false
			top: 0
			left: 0

	closeDropdown: (e) =>
		if @state.isOpen and (!@trigger.current? or !(@trigger.current.contains e.target)) and (!@menu.current? or !(@menu.current.contains e.target))
			e.stopPropagation()
			e.preventDefault()
			@setState isOpen: false
			if @props.onOpenChange? then @props.onOpenChange false

	closeOnScroll: (e) =>
		if @state.isOpen and (!@menu.current? or !(@menu.current.contains e.target))
			@setState isOpen: false
			if @props.onOpenChange? then @props.onOpenChange false

	openDropdown: () =>
		triggerRect = @trigger.current.getBoundingClientRect()
		if window.innerWidth - triggerRect.left < @props.maxMenuWidth
			right = window.innerWidth - triggerRect.right
		else
			left = triggerRect.left
		if window.innerHeight - triggerRect.top < @props.maxMenuHeight
			bottom = window.innerHeight - triggerRect.top
		else
			top = triggerRect.top + 10
		@setState {top: top, bottom: bottom, left: left, right: right, isOpen: true}
		if @props.onOpenChange? then @props.onOpenChange true

	componentDidMount: () ->
		document.addEventListener 'click', @closeDropdown, true
		document.addEventListener 'scroll', @closeOnScroll, true

	componentWillUnmount: () ->
		document.removeEventListener 'click', @closeDropdown, true
		document.removeEventListener 'scroll', @closeOnScroll, true

	render: ->
		div {
			className: styles.base
			ref: @trigger
			['data-type']: 'bevy-dropdown-menu'
			['data-test-id']: DataAttribute 'dropdown-menu-trigger'
		},
			div {
				className: styles.triggerWrapper
				onClick: (e) =>
					e.stopPropagation()
					if @state.isOpen
						if @props.onOpenChange? then @props.onOpenChange false
						@setState isOpen: false
					else
						@openDropdown()
			}, @props.trigger
			if @state.isOpen
				menu =
					div {
						ref: @menu
						className: styles.dropdownContent
						['data-test-id']: DataAttribute 'dropdown-menu-content'
						style:
							top: @state.top
							bottom: @state.bottom
							left: @state.left
							right: @state.right
							maxWidth: @props.maxMenuWidth
							maxHeight: @props.maxMenuHeight
					},
						_.map @props.options, (option) =>
							div
								['data-test-id']: if option['data-test-id']? then DataAttribute option['data-test-id']
								key: option.label
								onClick: (e) =>
									if @props.closeOnSelect
										@setState isOpen: false
										if @props.onOpenChange? then @props.onOpenChange false
									option.onClick e
								className: cnames [
									styles.dropdownItem,
									styles.disabled if option.isEnabled is false
								]
							, option.label
				ReactDOM.createPortal(menu, document.body)
