import _ from 'lodash'
import React from 'react'
import moment from 'moment-mini'
import {FormatterConsumer} from 'libs/formatter'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'

# Renderables
import { div, span, img, p } from 'react-dom-factories'

import { Link as _Link } from 'react-router-dom'
Link = React.createFactory _Link

# Data
import trLegal from 'data/local/legal'

import styles from './table.styl'


form =
	key: 'Form'
	label: trLegal.props.type
	width: 40
	render: ({ item }) ->
		div {
			className: cnames styles.compactText
			'data-test-id': DataAttribute 'documents-list-form-cell'
		},
			if item.form? then _.capitalize(item.form.name)

topic =
	key: 'Topic'
	label: trLegal.props.topic
	width: 40
	render: ({ item }) ->
		div
			className: cnames styles.compactText
			onClick: (e) -> e.stopPropagation()
			'data-test-id': DataAttribute 'documents-list-topic-cell'
		, do ->
			Link {className: styles.link, to: item.href},
				if item.topic?
					_.capitalize(item.topic.name)

registerNumber =
	key: 'registerNumber'
	label: 'Land & Mortgage register number'
	width: 60
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.registerNumber? then item.registerNumber else '-'

plotNumber =
	key: 'plotNumber'
	label: 'Plot number'
	width: 60
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.plotNumber? then item.plotNumber else '-'

pricientNumber =
	key: 'pricientNumber'
	label: 'Pricient Number'
	width: 60
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.pricientNumber? then item.pricientNumber else '-'

typeOwnership =
	key: 'typeOwnership'
	label: 'Type of ownership'
	width: 60
	render: ({ item }) ->
		span {className: cnames styles.compactText},
			if item.typeOwnership? then item.typeOwnership else '-'

export DASHBOARD_COLUMNS = do ->
	columns =
		[
			{...form}
			{...topic}
			{ ...registerNumber}
			{ ...plotNumber}
			{ ...pricientNumber}
			{...typeOwnership}
		]

