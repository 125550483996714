# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _Table from '@bevy/table'
Table = React.createFactory _Table

# Styles
import styles from './table.styl'
import { DASHBOARD_COLUMNS } from './columns'

getRowClassName = (item, index) ->
	cnames [
			if index % 2 then styles.odd else styles.even
	]

export default ({ items, loading}) ->
	if items?
		items = _.map items, (item) =>
			folder = if item.state is 'Commited' then 'registry' else 'inbox'
			href = "#{@props.location.pathname}/legal/#{folder}/#{item.id}"
			{
				...item
				href
			}
	div {className: styles.table},
		Table
			hideBorders: true
			loading: loading
			rowHeight: 24
			columns: DASHBOARD_COLUMNS
			emptyState: () ->
				div {className: styles.emptyState}, 'This list is empty...'
			itemsCount: _.size items
			items: items
			rowClassName: (item, index) -> if item? then getRowClassName item, index
