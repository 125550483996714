###
Component to display document header
###

# Libs
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import moment from 'moment-mini'
import DataAttribute from '@bevy/data-attribute'
import _ from 'lodash'

# Components
import { div, button, span, p } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import _Lozenge from '@atlaskit/lozenge'
Lozenge = React.createFactory _Lozenge

import _Avatar, {AvatarItem as _AvatarItem} from '@atlaskit/avatar'
Avatar = React.createFactory _Avatar
AvatarItem = React.createFactory _AvatarItem

import _Breadcrumbs, { BreadcrumbsItem as _BreadcrumbsItem } from '@atlaskit/breadcrumbs'
Breadcrumbs = React.createFactory _Breadcrumbs
BreadcrumbsItem = React.createFactory _BreadcrumbsItem

import { Link as _Link } from 'react-router-dom'
Link = React.createFactory _Link


import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

import _InlineEdit from '@bevy/inline-edit'
InlineEdit = React.createFactory _InlineEdit

import _Button from '@bevy/button'
Button = React.createFactory _Button

# import _Button from '@bevy/button'
# Button = React.createFactory _Button

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _EditableDocumentName from '@bevy/editable-document-name'
EditableDocumentName = React.createFactory _EditableDocumentName

#Styles
import styles from './index.styl'
import {
	refreshCw
	check
	alertCircle
	# arrowLeft
} from 'react-icons-kit/feather'
import {
	ic_lock
} from 'react-icons-kit/md'

class BreadcrumbLink extends React.Component
	render: ->
		{
			children
			className
			href
			onMouseEnter
			onMouseLeave
		} = @props
		Link {
			..._.pick @props, [
					'className'
					'onMouseEnter'
					'onMouseLeave'
				]
			to: href
		},
			children

export default class DocumentHeader extends React.Component
	@propTypes =
		compact: PropTypes.bool
		mini: PropTypes.bool
		title: PropTypes.node.isRequired
		description: PropTypes.string
		author: PropTypes.shape
			name: PropTypes.string
			image: PropTypes.string
		lastUpdate: PropTypes.number
		lastUpdateShort: PropTypes.bool
		status: PropTypes.oneOfType [
			PropTypes.string
			PropTypes.shape
				name: PropTypes.string.isRequired
				appearance: PropTypes.oneOf [
					'default'
					'inprogress'
					'moved'
					'new'
					'removed'
					'success'
				]
				isBold: PropTypes.bool
			]
		state: PropTypes.node
		sync: PropTypes.oneOf [
				'Saved'
				'Syncing'
				'Error'
			]
		actions: PropTypes.node
		# TODO: Add types for this props
		# action
		# owner
		# people
		# peopleAction
		# ownerAction
		# subtitle
		type: PropTypes.node
		uuid: PropTypes.node
		breadcrumbs: PropTypes.arrayOf PropTypes.shape
			href: PropTypes.string
			text: PropTypes.node.isRequired
		isLoading: PropTypes.bool
	@defaultProps:
		compact: false
		mini: false
		lastUpdateShort: false
		# edit: false
		# backArrow: true

	render: ->
		# console.log @props.owner, @props.people
		div {className: cnames [
			'bevy-document-header'
			if @props.compact then styles.compact
			if @props.mini then styles.mini
			@props.className
			styles.base
			if @props.edit then styles.edit
		]},
			div {className: cnames [
				styles.main
			]},
				div {className: cnames styles.documentInfo, 'bevy-document-header-document-info'},
					do => if @props.action?
						if @props.action.props? then @props.action
						else
							Button {
								...@props.action
								className: cnames @props.action.className, styles.action
							}
					div {
						className: cnames [
							styles.titleContainer
							if @props.specialIndicator? then styles.withIndicator
						]
						style: if @props.titleAtTheTop? then flexDirection: 'column-reverse'
					},
						if @props.subtitle? then span {className: cnames styles.subtitle, 'bevy-document-header-document-info-subtitle'}, @props.subtitle
						div {className: styles.aboveTitle},
							if !_.isEmpty @props.breadcrumbs
								Breadcrumbs {},
									_.map @props.breadcrumbs, (breadcrumb) ->
										BreadcrumbsItem {...breadcrumb, component: BreadcrumbLink}
									if @props.uuid?
										BreadcrumbsItem {
											text: "#{@props.type} [#{@props.uuid}]"
											component: BreadcrumbLink
										}


							else
								Fragment {},
									if @props.type? and _.isString @props.type then span {className: cnames styles.type, 'bevy-document-header-document-info-type'}, @props.type
									if @props.uuid? then span {className: cnames styles.uuid, 'bevy-document-header-document-info-uuid'}, @props.uuid
									if @props.locked is true
										span {className: styles.locked},
											Tooltip {
												content: @props.lockedDescription || 'Document is non-editable'
											},
												Icon icon: ic_lock, size: 14
						div {className: cnames styles.title, 'bevy-document-header-document-info-title'},
							if @props.specialIndicator?
								div { className: cnames styles.specialIndicator, @props.specialIndicator.className },
									Icon { ..._.omit @props.specialIndicator.icon, 'name'}
							EditableDocumentName
								value: @props.title || if @props.props? then @props.props.title
								isLoading: @props.isLoading
								onChange: @props.onTitleChange
								placeholder: @props.titlePlaceholder
								# forceEdit: true
						if @props.subtitle? then span {className: cnames styles.subtitle, 'bevy-document-header-document-info-subtitle'}, @props.subtitle
					div {className: cnames(styles.sidebar, 'bevy-document-header-sidebar')},
						div {className: styles.status},
							do =>
								if @props.note?
									div {className: styles.note}, @props.note
							# People display
							do =>
								div {className: styles.peopleWrapper},
									# AvatarItem
									do =>
										if @props.owner
											div {
												className: cnames styles.ownerWrapper, if @props.ownerAction? then styles.clicker
												onClick: @props.ownerAction
											},
												if @props.owner.name? then div {className: styles.ownerName}, @props.owner.name
												Avatar {
													...@props.owner
													size: 'xsmall'
												}
									do =>
										if !_.isEmpty @props.people
											Fragment {},
												div {className: styles.group}, _.map _.take(@props.people, 4), (person) =>
													# AvatarItem
													div {
														key: person.key
														className: if @props.peopleAction? then styles.clicker
														onClick: =>
															if @props.peopleAction?
																@props.peopleAction person
													},
														Avatar {
															...person
															size: 'small'
														}
												if _.size(@props.people) > 4
													div {className: styles.tooltipContainer},
														Tooltip {
															content: div {className: styles.tooltipContent}, _.map _.drop(@props.people, 4), (user) ->
																p {key: user.key}, user.name
														},
															div {
																className: cnames styles.aclCountWrapper, if @props.moreAction? then styles.clicker
																onClick: @props.moreAction
															},
																div {className: styles.aclCount}, "+#{_.size(@props.people) - 4}"
							do =>
								if @props.status?
									div {className: styles.lozenge},
											Lozenge
												appearance: if @props.status.appearance? then @props.status.appearance else 'default'
												isBold: @props.status.isBold
											,
												if @props.status.name? then @props.status.name else @props.status

							if @props.lastUpdate?
								Fragment {},
									div {className: cnames styles.modificationStatus, 'bevy-document-header-modificationStatus'},
										span {}, do =>
											if @props.lastUpdateShort is true
												weeks = moment().diff(moment.unix(@props.lastUpdate), 'weeks', true)
												days = moment().diff(moment.unix(@props.lastUpdate), 'days', true)
												if days < 1
													'today'
												else if weeks < 1
													"#{_.floor(days)} d. ago"
												else
													"#{_.floor(weeks)} w. ago"
											else
												"Last modified #{moment.unix(@props.lastUpdate).fromNow()}"
										do =>
											if @props.author?.name?
												Fragment {},
													span {}, ' by '
													span {className: styles.author}, @props.author.name
						do =>
							if @props.actions?
								div {className: cnames [ styles.actions, 'document-header-actions' ]},
									@props.actions
			# 			if @props.status?
			# 				div {className: styles.status},
			# 					div {className: styles.lozenge},
			# 						Lozenge
			# 							appearance: if @props.status.appearance? then @props.status.appearance else 'default'
			# 							isBold: @props.status.isBold
			# 						,
			# 							if @props.status.name? then @props.status.name else @props.status

				if @props.description?
					p {className: cnames styles.description}, @props.description

			# 		if @props.tags?
			# 			div {className: styles.tags},
			# 				_.map @props.tags, (tag) ->
			# 					div {className: styles.tag},
			# 						Lozenge
			# 							appearance: if tag.appearance? then tag.appearance else 'default'
			# 						,
			# 							if tag.name? then tag.name else tag

			# 		div {className: cnames styles.authorContainer, styles.description},
			# 			div {className: styles.author},
			# 				if @props.lastUpdate?
			# 						span {
			# 							'data-test-category': DataAttribute 'last-update'
			# 						}, "Last modified #{moment.unix(@props.lastUpdate).fromNow()}", if @props.author?.name? then " by #{@props.author.name}"
			# 			if @props.sync?
			# 				status =
			# 					name: @props.sync
			# 				switch @props.sync
			# 					when 'Syncing'
			# 						status.icon = refreshCw
			# 						status.className = styles.syncing
			# 					when 'Saved'
			# 						status.icon = check
			# 						status.className = styles.saved
			# 					when 'Error'
			# 						status.icon = alertCircle
			# 						status.className = styles.error
			# 				div {className: cnames [ styles.sync, status.className ]},
			# 					Tooltip {content: status.name},
			# 						Icon {icon: status.icon, size: 12}

			# div {className: cnames [ styles.sub ]},
			# 	if @props.state?
			# 		div {className: cnames [ styles.state, 'document-header-state' ]},
			# 			@props.state
			# 	if @props.actions?
			# 		div {className: cnames [ styles.actions, 'document-header-actions' ]},
			# 			@props.actions
